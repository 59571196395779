import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { environment } from '../environments/environment';
import { AuthorizationService } from './services';
import { SiteBanner } from './models';

import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    currentRoute: string = null;
    isSiteBannerDisplayed: boolean = false;
    @ViewChild(SiteBanner) siteBanner: SiteBanner;

    get isAdmin(): boolean {
        return this.authorizationService.isFeasyAdmin();
    }

    get userName(): string {
        return this.authorizationService.getUsername();
    }

    constructor(private authorizationService: AuthorizationService, private router: Router, private titleService: Title) {
        if (!environment.production) {
            this.titleService.setTitle('Feasy Util - ' + environment.name);
        }
    }

    checkAdminPage(): boolean {
        this.currentRoute = this.router.url.replace("/", "");
        return this.currentRoute?.toLowerCase().indexOf("admin") > -1;
    }

    setSiteBannerDisplayedFormat(isDisplayed: boolean): void {
        this.isSiteBannerDisplayed = isDisplayed;
    }
}
