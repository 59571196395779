export class OrgLevel {
    insDt: Date;
    insUserId: string;
    updtDt: Date;
    updtUserId: string;
}

export class OrgArea extends OrgLevel {
    areaId: number;
    areaName: string = '';
}

export class OrgCounty extends OrgLevel {
    countyId: number;
    stateCd: string = '';
    fipsStateCd: string = '';
    fipsCountyCd: string = '';
    countyName: string = '';
}

export class OrgDivision extends OrgLevel {
    areaId: number;
    areaName: string = '';
    divId: number;
    divName: string = '';
    supportDivInd: boolean = false;
}

export class OrgMarket extends OrgLevel {
    divId: number;
    divName: string = '';
    lawsonMktNbr: string = '';
    mktId: number;
    mktName: string = '';
}

export class OrgSubMarket extends OrgLevel {
    mktId: number;
    mktName: string = '';
    subMktId: number;
    subMktName: string = '';
}
