<div class="well panel">
    <h2><i class="fa fa-link"></i> Refresh Links</h2>
    <br>
    <div class="row">
        <div class="form-group col-sm-12">
            <label class="control-label">Master Community<span class="text-danger">*</span></label>
            <select id="mastercomm" class="form-control" (change)="onCommunitySiteChange($event.target.value)" [disabled]="masterCommunitySitesList.length === 0">
                <option [disabled]="true" [value]=-1>Select a Feasibility Site...</option>
                <option *ngFor="let communitySite of masterCommunitySitesList" [value]="communitySite.mstrCommId">
                    {{ communitySite.title | titlecase }}
                </option>
            </select>
        </div>

        <div class="form-group col-sm-12">
            <button type="button" class="btn btn-primary" [disabled]="isRefreshBtnDisabled" (click)="refreshLinks()"><i class="fa fa-refresh"></i> Refresh Links</button>
            &nbsp;&nbsp;
            <button type="button" class="btn btn-primary" [disabled]="isVisitSiteBtnDisabled" (click)="onVisitSiteClick()"><i class="fa fa-external-link"></i> Visit Site</button>
            &nbsp;&nbsp;
            <input type="checkbox" id="import" text="importdata" [(ngModel)]="importData" (change)="checkImport($event.target.checked)" />&nbsp;&nbsp;Include "Import Anaplan Data"
            &nbsp;&nbsp;
            <span *ngIf="isLoading">
                <img width="30" height="30" src="../../assets/loading_spinner-alternate.gif" alt=" Loading...">
            </span>
        </div>
        
        <div class="form-group col-sm-12" *ngIf="showMessages">
            <span id="msgOne" *ngIf="showImported"><i>Last Imported: {{ dateImported | date: 'short' }}</i></span><br />
            <span id="msgTwo" *ngIf="showSiteLocked"><i>Refresh not allowed. This Master Community site is locked down for approval or archived.</i></span>
        </div>
    </div>
</div>

<p-toast position="top-right"></p-toast>

<app-job-queue-list></app-job-queue-list>
