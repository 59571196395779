<div class="well panel" *ngIf="jobQueueList">
    <p-toast position="top-right"></p-toast>

    <h2 class="header-container">
        <i class="fa fa-list-ul"></i>&nbsp;{{title}}
    </h2>

    <div class="row job-queue-row">
        <div class="col-md-6 job-queue-col">
            <i class="fine-print">Estimated Wait Time: {{ estimatedWaitTime | timer }}</i>
        </div>
        <div class="col-md-6 job-queue-col">
            <button type="button" class="btn pull-right" *ngIf="!isCheckingTriggers && !loading && IsAdminPage"
                [ngClass]="{'btn-danger': jobProcessorStatus, 'btn-success': !jobProcessorStatus}"
                (click)="updateJobProcessorStatus()" [disabled]="!jobProcessorStatusButtonEnabled">
                <i class="fa"
                    [ngClass]="{'fa-stop-circle': jobProcessorStatus, 'fa-play-circle': !jobProcessorStatus}"></i>
                {{ jobProcessorStatus ? 'Stop' : 'Start' }} Job Processor
            </button>
        </div>
    </div>

    <div class="banner-error" *ngIf="jobProcessorChecked && !jobProcessorStatus">
        <div>
            <i class="fa fa-exclamation-circle fa-lg"></i>&nbsp;&nbsp;
            {{jobProcessorStatusMessage}}
        </div>
    </div>

    <p-table #dt styleClass="p-datatable-striped" [columns]="col" [value]="jobQueueList" [paginator]="false" [rows]="10"
        [responsive]="true" [loading]="loading">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-jobqueue let-columns="columns">
            <tr>
                <td *ngFor="let col of columns" style="overflow: hidden;">
                    <ng-container
                        *ngIf="col.field === 'jobCreatedDate' || col.field === 'jobDateUpdated' || col.field === 'jobStartedDate'; else second">
                        {{jobqueue[col.field] | date: 'shortTime' }}
                    </ng-container>

                    <ng-template #second>
                        <ng-container *ngIf="col.field === 'masterCommunity'; else third">
                            <ng-container
                                *ngIf="jobqueue['jobStatus'] === 'COMPLETED' && jobqueue['jobTypeDescription'] !== 'IMPORT ANAPLAN DATA' && jobqueue['jobTypeDescription'] !== 'IMPORT ANAPLAN COMMUNITIES' && jobqueue['jobTypeDescription'] !== 'FEASY DATA CLEANUP'">
                                <a href="{{sharepointSiteUrl}}/{{ jobqueue[col.field] }}" target="_blank">
                                    <button type="button" class="btn btn-sm btn-primary">
                                        <i class="fa fa-eye"></i> View
                                        Site
                                    </button>
                                </a>
                            </ng-container>

                            <ng-container *ngIf="!isLoading && jobqueue['jobStatus'] === 'IN-PROGRESS'">
                                <img src="../../assets/loading_spinner-alternate.gif" height="10px"> {{
                                jobqueue['jobStatusDescription']
                                }}
                            </ng-container>

                            <ng-container *ngIf="!isLoading && jobqueue['jobStatus'] === 'ERROR'">
                                <button type="button" class="btn btn-sm btn-danger" id="{{jobqueue['jobQueueId']}}"
                                    (click)="modalOpenClick($event.target.id)">
                                    <i class="fa fa-times-circle"></i> View Error
                                </button>
                            </ng-container>

                            <ng-container
                                *ngIf="IsAdminPage && !isLoading && (jobqueue['jobStatus'] === 'IN-QUEUE' || jobqueue['jobStatus'] === 'IN-PROGRESS')">
                                <button type="button" class="btn btn-sm btn-primary" id="{{jobqueue['jobQueueId']}}"
                                    (click)="cancelFeasyJob($event.target.id)">
                                    <i class="fa fa-ban"></i> Cancel Job
                                </button>
                            </ng-container>

                            <ng-container
                                *ngIf="!isLoading && (jobqueue['jobStatus'] === 'CANCELED' || jobqueue['jobStatus'] === 'CANCELLED')">
                                <i class="font-blue fa fa-info-circle"></i> {{ jobqueue['jobStatusDescription'] }}
                            </ng-container>
                        </ng-container>
                    </ng-template>

                    <ng-template #third>
                        <ng-container *ngIf="col.field === 'jobStatus'; else fourth">
                            <ng-container *ngIf="jobqueue['jobStatus'] === 'IN-QUEUE'">
                                <i class="font-blue fa fa-hourglass-start"></i>
                                {{ jobqueue[col.field] | titlecase }}
                            </ng-container>
                            <ng-container *ngIf="jobqueue['jobStatus'] === 'IN-PROGRESS'">
                                <i class="font-orange fa fa-hourglass-half"></i>
                                {{ jobqueue[col.field] | titlecase }}
                            </ng-container>
                            <ng-container *ngIf="jobqueue['jobStatus'] === 'CANCELED'">
                                <i class="fa fa-times-circle"></i>
                                {{ jobqueue[col.field] | titlecase }}
                            </ng-container>
                            <ng-container *ngIf="jobqueue['jobStatus'] === 'COMPLETED'">
                                <i class="font-green fa fa-check-circle"></i>
                                {{ jobqueue[col.field] | titlecase }}
                            </ng-container>
                            <ng-container *ngIf="jobqueue['jobStatus'] === 'ERROR'">
                                <i class="font-red fa fa-exclamation-circle"></i>
                                {{ jobqueue[col.field] | titlecase }}
                            </ng-container>
                        </ng-container>
                    </ng-template>
                    <ng-template #fourth>
                        <ng-container> {{ jobqueue[col.field] | titlecase }}</ng-container>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog position="center" header="Error Message for Job Ending at {{ updatedTimeStamp | date: 'short' }}"
    [(visible)]="showErrorModal" [draggable]="true" resizable="true" [modal]="true" [style]="{'max-width':'75%'}">
    <h4>{{ errorDivision }} - {{ errorMasterCommunityName }}</h4>
    <p class="p-m-0">
        {{ modalErrorMessageString }}
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="refreshErrorModal()" label="Close" class="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<p-dialog position="center" header="Cancel Message" [(visible)]="showCancelErrorModal" [draggable]="true" [modal]="true"
    [style]="{'max-width':'75%'}">
    <h4>Permissions Error</h4>
    <p class="p-m-0">
        Your account does not have the necessary privileges to cancel any jobs. If you find this to be incorrect, please
        contact TSC.
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="closeCancelErrorModal()" label="Close" class="p-button-text"></p-button>
    </ng-template>
</p-dialog>