import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';

import { JobQueue, JobQueueParam, Subsites } from '../models';
import { AnaplanService, AuthorizationService, JobQueueService, SiteService } from '../services';
import { JobQueueListComponent } from '../shared';

@Component({
    selector: 'app-refreshlinks',
    templateUrl: './refreshlinks.component.html',
    styleUrls: ['./refreshlinks.component.css']
})
export class RefreshlinksComponent implements OnInit {

    @ViewChild(JobQueueListComponent)
    jobQueueChild: JobQueueListComponent;

    dateImported: string;
    importBtnDisabled: boolean;
    importData: boolean;
    isLoading: boolean = false;
    isRefreshBtnDisabled: boolean;
    isVisitSiteBtnDisabled: boolean;
    selectedMasterCommunityId: string;
    mstrCommId: number;
    showImported: boolean;
    showJobQueue: boolean;
    showMessages: boolean = false;
    showSiteLocked: boolean = false;

    jobQueueList: JobQueue[] = [];
    masterCommunitySitesList: Subsites[];

    get username(): string {
        return this.authService.getUsername();
    }

    constructor(private anaplanService: AnaplanService, private jobQueueService: JobQueueService, private messageService: MessageService, private siteService: SiteService, private authService: AuthorizationService) { }

    ngOnInit(): void {
        this.masterCommunitySitesList = [];
        this.showImported = false;
        this.importBtnDisabled = true;
        this.isRefreshBtnDisabled = true;
        this.isVisitSiteBtnDisabled = true;
        this.importData = false;

        setTimeout(() => {
            this.getMasterCommunitySites();
        });
    }

    checkImport(event: any): void {
        this.importData = event;
    }

    onCommunitySiteChange(commId): void {
        this.isLoading = true;
        this.importBtnDisabled = true;
        this.isRefreshBtnDisabled = true;
        this.isVisitSiteBtnDisabled = true;
        this.showMessages = false;

        if (commId === '-1') {
            this.selectedMasterCommunityId = undefined;
            this.isLoading = false;
            return;
        } else {
            this.selectedMasterCommunityId = commId;
            this.siteService.getSiteContainsApprovedBooks(Number(this.selectedMasterCommunityId)).subscribe((res) => {
                if (res) {
                    this.showMessages = true;
                    this.importBtnDisabled = true;
                    this.isRefreshBtnDisabled = true;
                    this.showSiteLocked = true;
                    this.isVisitSiteBtnDisabled = false;
                } else {
                    this.showMessages = true;
                    this.importBtnDisabled = false;
                    this.isRefreshBtnDisabled = false;
                    this.showSiteLocked = false;
                    this.isVisitSiteBtnDisabled = false;
                }
                this.isLoading = false;
            }, err => {
                this.isLoading = false;
                console.log(err);
            });
        }

        this.anaplanService.getAnaplanFeasyMasterCommunityById(commId).subscribe((recordset) => {
            if (!recordset[0]) {
                this.showImported = false;
            } else {
                commId = recordset[0].mstrCommId;
                let date = recordset[0].dateCreated.toString();
                const splitDate = date.split('T');
                date = splitDate[0] + ' ' + splitDate[1] + ' UTC';
                const dateCreated = new Date(date);
                this.dateImported = dateCreated.toString();
                this.showImported = true;
            }
        }, err => {
            this.isLoading = false;
            console.log(err);
        });
    }

    getMasterCommunitySites(): void {
        this.isLoading = true;
        const roles = this.authService.getRoles();
        this.siteService.divisionCommunitySites(roles).subscribe((res) => {
            this.masterCommunitySitesList = res;
            this.isLoading = false;
        });
    }

    refreshLinks(): void {
        const param: JobQueueParam = new JobQueueParam();
        param.mstrCommId = parseInt(this.selectedMasterCommunityId, 10);
        param.jobType = 5;
        const user = this.username.split('@');
        param.submittedByUser = user[0].toLowerCase();
        this.isLoading = true;

        this.jobQueueService.postJobQueue(param, this.importData).subscribe((result) => {
            this.isLoading = false;

            if (this.importData) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Added',
                    detail: 'Import Anaplan Data request added to queue',
                });
            }

            this.messageService.add({
                severity: 'success',
                summary: 'Added',
                detail: 'Refresh Links request added to queue',
            });

            this.jobQueueChild.getJobQueues();
        }, err => {
            this.isLoading = false;
            console.log(err);

            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: err.message,
            });
        });
    }

    onVisitSiteClick(): void {
        window.open(this.masterCommunitySitesList.find(
            subsite => subsite.mstrCommId.toString().trim() === this.selectedMasterCommunityId.trim()
        ).url);
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
