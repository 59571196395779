import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timer'
})
export class TimerPipe implements PipeTransform {

    // This pipe takes a number of seconds and returns a string in the format of "X hours, Y minutes, Z seconds"
    transform(value: number): string {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const seconds = value % 60;
        const hoursLabel = hours === 1 ? 'hour' : 'hours';
        const minutesLabel = minutes === 1 ? 'minute' : 'minutes';
        const secondsLabel = seconds === 1 ? 'second' : 'seconds';

        var returnVal = ''
        if (hours > 0) {
            returnVal = `${hours.toString()} ${hoursLabel}, `;
        }
        if (minutes > 0) {
            returnVal += `${minutes.toString()} ${minutesLabel}, `;
        }
        if (seconds > 0) {
            returnVal += `${seconds.toString()} ${secondsLabel}`;
        }
        if (value === 0) {
            returnVal = `None`;
        }
        return returnVal;
    }
}
