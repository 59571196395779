<div class="well panel">
    <h2><i class="fa fa-paper-plane"></i> Report Request</h2>
    <br>

    <div class="row">
        <div class="form-group col-sm-12 col-md-6">

            <label class="control-label">Master Community<span class="text-danger">*</span></label>
            <select id="mastercomm" class="form-control" [disabled]="anaplanFeasyList === null"
                    (change)="onMasterCommChange($event.target.value)">
                <option [disabled]="true" [value]=-1>Select an Anaplan Community...</option>
                <option *ngFor="let feasy of anaplanFeasyList"
                        [value]="feasy.feasibilityName + '^^' + feasy.feasibilityCode + '^^' + feasy.division">
                    {{feasy.feasibilityName}} <span *ngIf="feasy.mstrCommId">- {{feasy.mstrCommId}}</span>
                </option>
            </select>
        </div>
        <div class="form-group col-sm-12 col-md-6">
            <label class="control-label">Current Communities<span class="text-danger">*</span></label>
            <ng-multiselect-dropdown class='comm-multi-select' [placeholder]="'Select a Community...'"
                                     (ngModelChange)="onCommunitySelectAll($event)" [(ngModel)]="preSelectedCommunities"
                                     [settings]="dropdownSettings" [data]="reportCommuntiyList" (onSelect)="onCommunityChange($event)"
                                     (onSelectAll)="onCommunitySelectAll($event)" (onDeSelect)="onCommIdDeSelect($event)"
                                     (onDeSelectAll)="onAllCommDeSelect()">
            </ng-multiselect-dropdown>
        </div>
    </div>

    <div class="row">
        <div class="form-group col-sm-6 col-md-3">
            <label class="control-label">Scenario<span class="text-danger">*</span></label>
            <select id="scenario" class="form-control"
                    [disabled]="lapScenarioList === null || scenarioEnabled === false || scenarioDropDownDisabled == true"
                    [(ngModel)]="lapScenario"
                    (change)="onScenarioChange($event.target.value, $event.target.selectedOptions[0].getAttribute('data-set'))">
                <option [disabled]="true" [value]=-1>Select a Scenario...</option>
                <option *ngFor="let scenario of lapScenarioList" [value]="scenario.scenarioId"
                        [attr.data-set]="scenario.scenarioName">
                    {{scenario.scenarioName.trim()}}
                </option>
            </select>
        </div>
        <div class="form-group col-sm-6 col-md-3">
            <br>
            <input type="radio" id="singleScenario" name="scenarioselect" value="single"
                   [checked]="runCurrentScenarioBtnSelected"
                   (change)="onRunScenarioChange($event.target.value)">&nbsp;&nbsp;
            <label class='plaintext' for="singleScenario">Run Current Scenario</label>
            <br>
            <input type="radio" id="allScenario" name="scenarioselect" value="all"
                   (change)="onRunScenarioChange($event.target.value)">&nbsp;&nbsp;
            <label class='plaintext' for="allScenario">Run All Scenarios</label>
        </div>
        <div class="form-group col-sm-12 col-md-6">
            <label class="control-label">Report Name<span class="text-danger">*</span></label>
            <select id="report" class="form-control" [disabled]="reportDefinitionAndDetailList === null"
                    (change)="onReportChange($event.target.value)">
                <option [disabled]="true" [value]=-1>Select a Feasibility Report...</option>
                <option *ngFor="let rpt of reportDefinitionAndDetailList" [value]="rpt.reportId">
                    {{rpt.reportName.trim()}}
                </option>
            </select>
        </div>
    </div>

    <div class="row">
        <div class="form-group col-sm-6 col-md-3">
            <label class="control-label">Period Selection*</label>
            <br>
            <input type="radio" id="qtr" name="periodselect" value="quarter"
                   (change)="onPeriodChange($event.target.value)">&nbsp;&nbsp;
            <label class='plaintext' for="qtr">Include Quarter Totals</label>
            <br>
            <input type="radio" id="month" name="periodselect" value="month" [checked]="includeMonthTotal"
                   (change)="onPeriodChange($event.target.value)">&nbsp;&nbsp;
            <label class='plaintext' for="month">Include Month Totals</label>
        </div>
        <div class="form-group col-sm-6 col-md-9">
            <label class="control-label">Report Options</label>
            <br>
            <input type="checkbox" id="fullbook" text="test" value="1234" [checked]="includeSummary"
                   (change)="checkIfIncludeSummary($event.target)">&nbsp;&nbsp;
            <label class='plaintext' for="fullbook">Include Summary Report</label>
        </div>
    </div>

    <button type="button" class="btn btn-primary" [disabled]="runRptBttnDisabled === true" (click)="onRunReportClick()">
        <i class="fa fa-file-o"></i> Run Report
    </button>&nbsp;&nbsp;

    <button type="button" class="btn btn-primary" (click)="onImportAnaplanData()"
            [disabled]="importBtnDisabled === true">
        <i class="fa fa-download"></i> Import Anaplan Data
    </button>&nbsp;&nbsp;

    <button *ngIf="showJobQueue===false" type="button" class="btn btn-secondary" (click)="hideShowJobQueue()">
        <i class="fa fa-eye"></i> Show Job Queue
    </button>&nbsp;&nbsp;

    <button *ngIf="showJobQueue===true" type="button" class="btn btn-secondary" (click)="hideShowJobQueue()">
        <i class="fa fa-eye-slash"></i> Hide Job Queue
    </button>&nbsp;&nbsp;

    <ng-container *ngIf="showNotImported">
        Data not yet imported for this Master Community. Please import Anaplan data before running reports.
    </ng-container>

    <ng-container *ngIf="showImported">
        <i>Last Imported: {{ dateImported | date: 'short' }}</i>
    </ng-container>
    &nbsp;&nbsp;
    <ng-container *ngIf="isLoading">
        <img width="30" height="30" src="../../assets/loading_spinner-alternate.gif" alt=" Loading...">
    </ng-container>
    <button type="button" class="btn btn-secondary pull-right" (click)="onImportAnaplanCommunitiesClick()"
        [disabled]="disableImportAnaplanCommunities === true"><i class="fa fa-align-right"></i> Import Anaplan
        Communities</button>

    <div id="reportContainer" *ngIf="runReport" style="height:800px;"></div>
</div>

<ng-container *ngIf="showJobQueue">
    <app-job-queue-list></app-job-queue-list>
</ng-container>
