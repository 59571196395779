import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';

import { CreateBookOptions, JobQueue, JobQueueParam, Subsites } from '../models';
import { AuthorizationService, JobQueueService, SiteService } from '../services';
import { JobQueueListComponent } from '../shared';

@Component({
    selector: 'app-createbook',
    templateUrl: './createbook.component.html',
    styleUrls: ['./createbook.component.css']
})
export class CreatebookComponent implements OnInit {

    @ViewChild(JobQueueListComponent)
    jobQueueChild: JobQueueListComponent;

    selectedMstrCommId: number = undefined;
    isButtonReady: boolean;
    isLoading: boolean;
    bookOptions: CreateBookOptions = new CreateBookOptions();
    CommunitySites: Subsites[] = [];
    jobQueueList: JobQueue[] = [];

    get username(): string {
        return this.authService.getUsername();
    }

    constructor(private authService: AuthorizationService, private jobQueueService: JobQueueService, private messageService: MessageService, private siteService: SiteService) { }

    ngOnInit(): void {
        this.isButtonReady = false;
        this.isLoading = false;
        this.resetBookOptions();
        this.getSharepointCommunities();
    }

    onCommunitySiteChange(mstrCommId): void {
        if (mstrCommId === -1) {
            this.selectedMstrCommId = undefined;
        } else {
            this.selectedMstrCommId = mstrCommId;
        }

        this.isButtonReady = false;
        this.resetBookOptions();
    }

    checkButtonReady(): void {
        if ((this.bookOptions.bBook === true || this.bookOptions.execBook === true ||
            this.bookOptions.fullBook === true || this.bookOptions.preFeasyBook === true) && this.selectedMstrCommId !== undefined) {
            this.isButtonReady = true;
        }
        else {
            this.isButtonReady = false;
        }
    }

    getSharepointCommunities(): void {
        this.isLoading = true;
        const roles = this.authService.getRoles();
        this.siteService.divisionCommunitySites(roles).subscribe((recordset) => {
            this.CommunitySites = recordset;
            this.isLoading = false;
        }, err => {
            console.log(err);
            this.isLoading = false;
        });
    }

    onCreateBookClick(): void {
        const user = this.username.split('@');

        if (this.bookOptions.bBook === true) {
            const param: JobQueueParam = new JobQueueParam();
            param.mstrCommId = this.selectedMstrCommId;
            param.jobType = 4;
            param.submittedByUser = user[0].toLowerCase();

            this.isLoading = true;
            this.jobQueueService.postJobQueue(param).subscribe((result) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Added',
                    detail: 'Create B Book request added to queue',
                });

                this.jobQueueChild.getJobQueues();
            }, err => {
                console.log(err);
                this.isLoading = false;

                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: err.message,
                });
            });
        }

        if (this.bookOptions.execBook === true) {
            const param: JobQueueParam = new JobQueueParam();
            param.mstrCommId = this.selectedMstrCommId;
            param.jobType = 3;
            param.submittedByUser = user[0].toLowerCase();

            this.isLoading = true;
            this.jobQueueService.postJobQueue(param).subscribe((result) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Added',
                    detail: 'Create Executive Book request added to queue',
                });

                this.jobQueueChild.getJobQueues();
            }, err => {
                console.log(err);
                this.isLoading = false;

                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: err.message,
                });
            });
        }

        if (this.bookOptions.fullBook === true) {
            const param: JobQueueParam = new JobQueueParam();
            param.mstrCommId = this.selectedMstrCommId;
            param.jobType = 2;
            param.submittedByUser = user[0].toLowerCase();

            this.isLoading = true;
            this.jobQueueService.postJobQueue(param).subscribe((result) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Added',
                    detail: 'Create Full Book request added to queue',
                });

                this.jobQueueChild.getJobQueues();
            }, err => {
                console.log(err);
                this.isLoading = false;

                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: err.message,
                });
            });
        }

        if (this.bookOptions.preFeasyBook === true) {
            const param: JobQueueParam = new JobQueueParam();
            param.mstrCommId = this.selectedMstrCommId;
            param.jobType = 1;
            param.submittedByUser = user[0].toLowerCase();

            this.isLoading = true;
            this.jobQueueService.postJobQueue(param).subscribe((result) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Added',
                    detail: 'Create Pre-Feasy Book request added to queue',
                });

                this.jobQueueChild.getJobQueues();
            }, err => {
                console.log(err);
                this.isLoading = false;

                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: err.message,
                });
            });
        }

        this.resetBookOptions();
    }

    resetBookOptions(): void {
        //  init the create book options view model
        this.bookOptions.bBook = false;
        this.bookOptions.execBook = false;
        this.bookOptions.fullBook = false;
        this.bookOptions.preFeasyBook = false;
        this.isButtonReady = false;
    }
}
