import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';

import { SiteBanner } from '../../models';
import { AuthorizationService, SiteBannerService } from '../../services';
import { SiteBannerComponent } from '../../shared';

@Component({
    selector: 'app-site-banner-manager',
    templateUrl: './site-banner-manager.component.html',
    styleUrls: ['./site-banner-manager.component.css']
})
export class SiteBannerManagerComponent implements OnInit {

    bannerTypes: any[] = [
        { label: 'Information', value: 'banner-information' },
        { label: 'Warning', value: 'banner-warning' },
        { label: 'Error', value: 'banner-error' },
        { label: 'Success', value: 'banner-success' }
    ];
    isErrorSelected: boolean = false;
    isInfoSelected: boolean = false;
    isSuccessSelected: boolean = false;
    isWarningSelected: boolean = false;

    isLoading: boolean = false;
    siteBanner: SiteBanner;

    @ViewChild(SiteBannerComponent)
    siteBannerChild: SiteBannerComponent;

    get username(): string {
        return this.authService.getUsername();
    }

    constructor(private authService: AuthorizationService, private messageService: MessageService, private siteBannerService: SiteBannerService) { }

    ngOnInit(): void {
        this.siteBanner = new SiteBanner();
        this.getSiteBannerInfo();
        this.siteBannerService.userHasClosed = false;
    }

    checkDisplay(isDisplayed: boolean): void {
        this.siteBanner.isDisplayed = isDisplayed;
    }

    getSiteBannerInfo(): void {
        this.isLoading = true;
        this.siteBannerService.getSiteBannerInfo().subscribe((data) => {
            this.siteBanner = data;
            this.isLoading = false;
            this.setButtonBorder(this.siteBanner.bannerType);
        });
    }

    onBannerTypeChange(bannerType: string): void {
        this.siteBanner.bannerType = bannerType;
        this.setButtonBorder(bannerType);
    }

    onSubmitClick(): void {
        if (confirm('Are you sure you want to update the site banner? \r\n \r\nPlease take some time to review the example to ensure the banner is formatted as intended.')) {
            this.isLoading = true;
            this.siteBanner.lastUpdatedBy = this.username.split('@')[0];
            this.siteBannerService.updateSiteBannerInfo(this.siteBanner).subscribe((data) => {
                this.siteBanner = data;
                this.siteBannerService.userHasClosed = false;
                this.isLoading = false;
                this.messageService.add({ severity: 'success', summary: 'Save Successful', detail: 'Site Banner table updated successfully.' });
            }), (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error Occured', detail: 'An error occured during update to Site Banner table.' });
                this.isLoading = false;
            };
        }
    }

    onInformationClick(): void {
        this.siteBanner.bannerType = 'banner-information';
        this.siteBanner.bannerHeader = 'Upcoming Release';
        this.siteBanner.bannerText = 'There is a scheduled release for the Feasy Utility on (specific date) at (specific time). Please be aware that the system will be unavailable during this time.';
        this.siteBanner.isDisplayed = true;
        this.siteBannerChild.displayClass = 'in';
        this.setButtonBorder(this.siteBanner.bannerType);
    }

    onWarningClick(): void {
        this.siteBanner.bannerType = 'banner-warning';
        this.siteBanner.bannerHeader = 'Ongoing Errors';
        this.siteBanner.bannerText = 'Corporate Systems IT is aware of an issue affecting (specific job). We are working to resolve the issue as quickly as possible.';
        this.siteBannerService.userHasClosed = false;
        this.siteBanner.isDisplayed = true;
        this.siteBannerChild.displayClass = 'in';
        this.setButtonBorder(this.siteBanner.bannerType);
    }

    onErrorClick(): void {
        this.siteBanner.bannerType = 'banner-error';
        this.siteBanner.bannerHeader = 'Ongoing Outage';
        this.siteBanner.bannerText = 'Corporate Systems IT is aware of an issue affecting (specific job). We are working to resolve the issue as quickly as possible.';
        this.siteBannerService.userHasClosed = false;
        this.siteBanner.isDisplayed = true;
        this.siteBannerChild.displayClass = 'in';
        this.setButtonBorder(this.siteBanner.bannerType);
    }

    onSuccessClick(): void {
        this.siteBanner.bannerType = 'banner-success';
        this.siteBanner.bannerHeader = 'All Systems Operational';
        this.siteBanner.bannerText = 'There are no current issues to report.';
        this.siteBannerService.userHasClosed = false;
        this.siteBanner.isDisplayed = true;
        this.siteBannerChild.displayClass = 'in';
        this.setButtonBorder(this.siteBanner.bannerType);
    }

    setButtonBorder(bannerType: string): void {
        switch (bannerType) {
            case 'banner-information':
                this.isInfoSelected = true;
                this.isErrorSelected = false;
                this.isSuccessSelected = false;
                this.isWarningSelected = false;
                break;
            case 'banner-warning':
                this.isInfoSelected = false;
                this.isErrorSelected = false;
                this.isSuccessSelected = false;
                this.isWarningSelected = true;
                break;
            case 'banner-error':
                this.isInfoSelected = false;
                this.isErrorSelected = true;
                this.isSuccessSelected = false;
                this.isWarningSelected = false;
                break;
            case 'banner-success':
                this.isInfoSelected = false;
                this.isErrorSelected = false;
                this.isSuccessSelected = true;
                this.isWarningSelected = false;
                break;
            default:
                this.isInfoSelected = false;
                this.isErrorSelected = false;
                this.isSuccessSelected = false;
                this.isWarningSelected = false;
                break;
        }
    }
}
