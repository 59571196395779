import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';

import { environment } from '../../environments/environment';
import { SharedService } from '.';
import { SiteBanner } from '../models';

@Injectable()
export class SiteBannerService implements OnInit {

    userHasClosed: boolean;

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    ngOnInit(): void {
        this.userHasClosed = false;
    }

    getSiteBannerInfo(): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/SiteBanner/';
        return this.http.get(`${getUrl}GetSiteBannerInfo`, opts);
    }

    updateSiteBannerInfo(siteBannerInfo: SiteBanner): any {
        const opts = this.sharedService.getOptsJson();
        const postUrl = environment.baseUrl + '/SiteBanner/';
        return this.http.put(`${postUrl}UpdateSiteBannerInfo`, siteBannerInfo, opts);
    }
}
