export class SiteBanner {
    bannerId: number;
    isDisplayed: boolean;
    bannerHeader: string;
    bannerText: string;
    bannerType: string;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;

    constructor() {
        this.isDisplayed = false;
    }
}

