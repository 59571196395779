import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';

import { OrgArea, OrgCounty, OrgDivision, OrgLevel, OrgMarket, OrgSubMarket } from '../../../models';
import { OrgService } from '../../../services';

@Component({
    selector: 'app-add-org',
    templateUrl: './add-org.component.html',
    styleUrls: ['./add-org.component.css']
})

export class AddOrgComponent implements OnInit {
    @Input() orgLevels: string[] = [];
    @Input() showAddOrgModal: boolean = false;
    @Input() selectedOrgLevel: string;
    @Output() modalClose = new EventEmitter();

    dropdownAreas: OrgArea[] = [];
    dropdownDivisions: OrgDivision[] = [];
    dropdownMarkets: OrgMarket[] = [];
    isLoading: boolean = false;
    isSaveReady: boolean = false;
    newOrgObj: OrgLevel;

    constructor(private messageService: MessageService, private orgService: OrgService) { }

    ngOnInit() {
        if (this.orgLevels.length == 0) {
            this.orgLevels = ['Area', 'County', 'Division', 'Market', 'SubMarket'];
            this.selectedOrgLevel = '0';
        }
        if (this.selectedOrgLevel != '0') {
            this.onOrgLevelChange(this.selectedOrgLevel);
        }
    }

    onAddModalClose(isRefreshQuery): void {
        this.modalClose.emit([isRefreshQuery, this.selectedOrgLevel]);
        this.showAddOrgModal = false;
    }

    onAddOrg(): void {
        this.orgService.createOrg(this.newOrgObj, this.selectedOrgLevel).subscribe(res => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: this.selectedOrgLevel + ' added successfully' });
            this.onAddModalClose(true);
        }, err => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: this.selectedOrgLevel + ' add failed' });
        })
    }

    onFieldChange(): void {
        switch (this.selectedOrgLevel) {
            case 'Area':
                if ((this.newOrgObj as OrgArea).areaName.length > 0) {
                    this.isSaveReady = true;
                } else {
                    this.isSaveReady = false;
                }
                break;
            case 'County':
                if ((this.newOrgObj as OrgCounty).countyName.length > 0 &&
                    (this.newOrgObj as OrgCounty).stateCd.length == 2 &&
                    (this.newOrgObj as OrgCounty).fipsStateCd.length == 2 &&
                    (this.newOrgObj as OrgCounty).fipsCountyCd.length === 3) {
                    this.isSaveReady = true;
                } else {
                    this.isSaveReady = false;
                }
                break;
            case 'Division':
                if ((this.newOrgObj as OrgDivision).divName.length > 0 &&
                    (this.newOrgObj as OrgDivision).areaId > 0) {
                    this.isSaveReady = true;
                } else {
                    this.isSaveReady = false;
                }
                break;
            case 'Market':
                if ((this.newOrgObj as OrgMarket).mktName.length > 0 &&
                    (this.newOrgObj as OrgMarket).lawsonMktNbr.length == 4 &&
                    (this.newOrgObj as OrgMarket).divId > 0) {
                    this.isSaveReady = true;
                } else {
                    this.isSaveReady = false;
                }
                break;
            case 'SubMarket':
                if ((this.newOrgObj as OrgSubMarket).subMktName.length > 0 &&
                    (this.newOrgObj as OrgSubMarket).mktId > 0) {
                    this.isSaveReady = true;
                } else {
                    this.isSaveReady = false;
                }
                break;
            default:
                this.isSaveReady = false;
                break;
        }
    }

    onOrgLevelChange(val: string) {
        this.selectedOrgLevel = val;
        this.isLoading = true;
        switch (this.selectedOrgLevel) {
            case 'Area':
                this.newOrgObj = new OrgArea();
                break;
            case 'County':
                this.newOrgObj = new OrgCounty();
                break;
            case 'Division':
                this.newOrgObj = new OrgDivision();
                this.orgService.getOrgs('Area').subscribe(areas => {
                    this.dropdownAreas = areas;
                    this.isLoading = false;
                });
                break;
            case 'Market':
                this.newOrgObj = new OrgMarket();
                this.orgService.getOrgs('Division').subscribe(divisions => {
                    this.dropdownDivisions = divisions;
                    this.isLoading = false;
                });
                break;
            case 'SubMarket':
                this.newOrgObj = new OrgSubMarket();
                this.orgService.getOrgs('Market').subscribe(markets => {
                    this.dropdownMarkets = markets;
                    this.isLoading = false;
                });
                break;
            default:
                this.newOrgObj = null;
                break;
        }
    }
}