<p-dialog position="center" header="Add Org Level" [(visible)]="showAddOrgModal" [draggable]="true" resizable="true" [modal]="true" [style]="{'max-width':'50%', 'min-width': '50%'}">
    <div class="form-group col-sm-12">
        <label class="control-label">Org Level </label>
        <select class="form-control" (change)="onOrgLevelChange($event.target.value)" [(ngModel)]="selectedOrgLevel">
            <option [disabled]="true" [selected]="true" [value]="0" >Select an Org Level...</option>
            <option *ngFor="let org of orgLevels" [value]="org">
                {{ org }}
            </option>
        </select>
        <br>
    </div>
    <div class="row"></div>
    <!-- ***** Area ***** -->
    <div *ngIf="selectedOrgLevel == 'Area'">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="control-label" for="areaName">Area Name</label>
                <input type="text" class="form-control" id="areaName" [maxlength]="50" (keyup)="onFieldChange()" [(ngModel)]="newOrgObj.areaName">
            </div>
        </div>
    </div>
<!-- ***** County ***** -->
    <div *ngIf="selectedOrgLevel == 'County'">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="countyName">County Name</label>
                <input type="text" class="form-control" id="countyName" [maxlength]="50" (keyup)="onFieldChange()" [(ngModel)]="newOrgObj.countyName">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="stateCd">State Code</label>
                <input type="text" class="form-control" id="stateCd" [maxlength]="2" (keyup)="onFieldChange()" [(ngModel)]="newOrgObj.stateCd">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="fipsStateCd">FIPS State Code</label>
                <input type="text" class="form-control" id="fipsStateCd" [maxlength]="2" (keyup)="onFieldChange()" [(ngModel)]="newOrgObj.fipsStateCd">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="fipsCountyCd">FIPS County Code</label>
                <input type="text" class="form-control" id="fipsCountyCd" [maxlength]="3" (keyup)="onFieldChange()" [(ngModel)]="newOrgObj.fipsCountyCd">
            </div>
        </div>
    </div>
<!-- ***** Division ***** -->
    <div *ngIf="selectedOrgLevel == 'Division'">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="control-label" for="divName">Division Name</label>
                <input type="text" class="form-control" id="divName" [maxlength]="50" (keyup)="onFieldChange()" [(ngModel)]="newOrgObj.divName">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="area">Area</label>
                <select class="form-control" id="area" [(ngModel)]="newOrgObj.areaId" (change)="onFieldChange()">
                    <option *ngFor="let area of dropdownAreas" [value]="area.areaId">
                        {{ area.areaName }}
                    </option>    
                </select>
                <span *ngIf="isLoading">
                    <img src="../../../assets/loading_spinner-alternate.gif" alt=" Loading...">
                </span>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="supportDivInd">Support Division Indicator</label>
                <input type="checkbox" class="form-control" id="supportDivInd" [(ngModel)]="newOrgObj.supportDivInd" onclick="onFieldChange()">
            </div>
        </div>
    </div>
<!-- ***** Market ***** -->
    <div *ngIf="selectedOrgLevel == 'Market'">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="control-label" for="mktName">Market Name</label>
                <input type="text" class="form-control" id="mktName" [maxlength]="100" (keyup)="onFieldChange()" [(ngModel)]="newOrgObj.mktName">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="lawsonMktNbr">Lawson Market Number</label>
                <input type="text" class="form-control" id="lawsonMktNbr" [maxlength]="4" (keyup)="onFieldChange()" [(ngModel)]="newOrgObj.lawsonMktNbr">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="division">Division</label>
                <select class="form-control" id="division" [(ngModel)]="newOrgObj.divId" (change)="onFieldChange()">
                    <option *ngFor="let div of dropdownDivisions" [value]="div.divId">
                        {{ div.divName }}
                    </option>
                </select>
                <span *ngIf="isLoading">
                    <img src="../../../assets/loading_spinner-alternate.gif" alt=" Loading...">
                </span>
            </div>
        </div>
    </div>
<!-- ***** SubMarket ***** -->
    <div *ngIf="selectedOrgLevel == 'SubMarket'">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="subMktName">SubMarket Name</label>
                <input type="text" class="form-control" id="subMktName" [maxlength]="100" (keyup)="onFieldChange()" [(ngModel)]="newOrgObj.subMktName">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="market">Market</label>
                <select class="form-control" id="market" [(ngModel)]="newOrgObj.mktId" (change)="onFieldChange()">
                    <option *ngFor="let mkt of dropdownMarkets" [value]="mkt.mktId">
                        {{ mkt.mktName }}
                    </option>
                </select>
                <span *ngIf="isLoading">
                    <img src="../../../assets/loading_spinner-alternate.gif" alt=" Loading...">
                </span>
            </div>
        </div>
    </div>
    
    <ng-template pTemplate="footer">
        <div>
            <button type="button" class="btn btn-success" [disabled]="!isSaveReady" (click)="onAddOrg()">
                <i class="fa fa-plus"></i>&nbsp; Add
            </button>
            &nbsp;
            <button type="button" class="btn btn-secondary" (click)="onAddModalClose()">
                <i class="fa fa-times-circle"></i>&nbsp; Close
            </button>
            
        </div>
    </ng-template>

</p-dialog>
