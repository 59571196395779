import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from './services';

// authentication guard for oidc implementation
// if we do not have a valid ticket, redirect to home screen (blank with login prompt)
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authorizationService: AuthorizationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const requiredClaim = route.data.requiresClaim || null;

        if (this.authorizationService.authenticated() && !this.authorizationService.hasClaim(requiredClaim)) {
            this.router.navigate(['unauthorized']);
            return false;
        }

        return true;
    }
}
