export class AnaplanCommunity {
    id: number;
    communityCode: string;
    communityName: string;
    feasibilityCode: string;
    feasibilityName: string;
    division: string;
    divisionName: string;
    dateCreated: Date;
    mstrCommId: number;
}

export class ImportAnaplanParam {
    feasibilityCode: string;
    mstrCommId: number;
    communityName: string ;
}
