<div class="well panel">
    <h2> <i class="fa fa-history fa-lg"></i> Job Tracking History</h2>
    <div>
        <label class="control-label">Master Community</label>
        <select class="form-control" [(ngModel)]="selectedMstrCommId" [disabled]="CommunitySites === null">
            <option [disabled]="true" [selected]="true" [value]="0">Select a Feasibility Site...</option>
            <option *ngFor="let site of CommunitySites" [value]="site.mstrCommId">{{site.title}}</option>
        </select>

        <br />
        <label class="control-label">Job Type</label>
        <select [(ngModel)]="selectedJobTypeId" class="form-control" [disabled]="isLoading">
            <option [disabled]="true" [selected]="true" [value]="0">Select a Job Type...</option>
            <option *ngFor="let type of jobTypes" [value]="type.id">
                {{ type.label }}
            </option>
        </select>

        <div class="button-area row">
            <div class="form-group col-sm-12">
                <button type="button" class="btn btn-primary" (click)="onSubmit()"
                    [disabled]="!selectedMstrCommId || !selectedJobTypeId">
                    <i class="fa fa-search"></i>&nbsp; Search
                </button>
                <img src="../../assets/loading_spinner-alternate.gif" *ngIf="isLoading" class="smaller-loader"
                    alt=" Loading...">
            </div>
        </div>
        <p><i>Note: Tracking history will only go back 90 days</i></p>
    </div>

    <div class="row" *ngIf="selectedJobTypeId != null && isSubmitted">
        <div class="well panel">
            <h2><i class="fa fa-clock-o fa-lg" *ngIf="selectedJobTypeId == 1" aria-hidden="true"></i>
                <i class="fa fa-exclamation-circle fa-lg" *ngIf="selectedJobTypeId == 2" aria-hidden="true"></i>
                <i class="fa fa-exclamation-circle fa-lg" *ngIf="selectedJobTypeId == 3" aria-hidden="true"></i>
                <i class="fa fa-exclamation-circle fa-lg" *ngIf="selectedJobTypeId == 4" aria-hidden="true"></i>
                <i class="fa fa-exclamation-circle fa-lg" *ngIf="selectedJobTypeId == 5" aria-hidden="true"></i>
                <i class="fa fa-exclamation-circle fa-lg" *ngIf="selectedJobTypeId == 6" aria-hidden="true"></i>
                <i class="fa fa-exclamation-circle fa-lg" *ngIf="selectedJobTypeId == 7" aria-hidden="true"></i>
                <i class="fa fa-exclamation-circle fa-lg" *ngIf="selectedJobTypeId == 8" aria-hidden="true"></i>

                &nbsp;{{submittedJobTypeTitle}} &nbsp;
            </h2>
            <br />
            <p-table #dt styleClass="p-datatable-striped" [columns]="col" [value]="jobHistoryData" [paginator]="false"
                [rows]="10" [responsive]="true" [loading]="isLoading">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-jobqueue let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns" style="overflow: hidden;">
                            <ng-container
                                *ngIf="col.field === 'jobCreatedDate' || col.field === 'jobDateUpdated' || col.field === 'jobStartedDate'; else second">
                                {{jobqueue[col.field] | date: 'MM-dd-yyyy ' }}{{jobqueue[col.field] | date: 'shortTime'
                                }}
                            </ng-container>

                            <ng-template #second>
                                <ng-container *ngIf="col.field === 'masterCommunity'; else third">
                                    <ng-container
                                        *ngIf="jobqueue['jobStatus'] === 'COMPLETED' && jobqueue['submittedJobTypeTitle'] !== 'IMPORT ANAPLAN DATA' && jobqueue['submittedJobTypeTitle'] !== 'IMPORT ANAPLAN COMMUNITIES'">
                                        <a href="{{sharepointSiteUrl}}/{{ jobqueue[col.field] }}" target="_blank">
                                            <button type="button" class="btn btn-sm btn-primary"><i
                                                    class="fa fa-eye"></i> View
                                                Site</button></a>
                                        &nbsp;
                                        <button pButton type="button" icon="pi pi-search" label="View History"
                                            (click)="openPopup(jobqueue['jobQueueId'])"></button>
                                    </ng-container>

                                    <ng-container *ngIf="!isLoading && jobqueue['jobStatus'] === 'IN-PROGRESS'">
                                        <img src="../../assets/loading_spinner-alternate.gif" height="10px"> {{
                                        jobqueue['jobStatusDescription'] }}
                                    </ng-container>

                                    <ng-container *ngIf="!isLoading && jobqueue['jobStatus'] === 'ERROR'">
                                        <button type="button" class="btn btn-sm btn-danger"
                                            id="{{jobqueue['jobQueueId']}}" (click)="modalOpenClick($event.target.id)">
                                            <i class="fa fa-times-circle"></i> View Error
                                        </button>
                                        &nbsp;
                                        <button pButton type="button" icon="pi pi-search" label="View History"
                                            (click)="openPopup(jobqueue['jobQueueId'])"></button>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="!isLoading && (jobqueue['jobStatus'] === 'CANCELED' || jobqueue['jobStatus'] === 'CANCELLED')">
                                        <i class="font-blue fa fa-info-circle"></i> {{ jobqueue['jobStatusDescription']
                                        }}
                                    </ng-container>
                                </ng-container>
                            </ng-template>

                            <ng-template #third>
                                <ng-container *ngIf="col.field === 'jobStatus'; else fourth">
                                    <ng-container *ngIf="jobqueue['jobStatus'] === 'IN-QUEUE'">
                                        <i class="font-blue fa fa-hourglass-start"></i>
                                        {{ jobqueue[col.field] | titlecase }}
                                    </ng-container>
                                    <ng-container *ngIf="jobqueue['jobStatus'] === 'IN-PROGRESS'">
                                        <i class="font-orange fa fa-hourglass-half"></i>
                                        {{ jobqueue[col.field] | titlecase }}
                                    </ng-container>
                                    <ng-container *ngIf="jobqueue['jobStatus'] === 'CANCELED'">
                                        <i class="fa fa-times-circle"></i>
                                        {{ jobqueue[col.field] | titlecase }}
                                    </ng-container>
                                    <ng-container *ngIf="jobqueue['jobStatus'] === 'COMPLETED'">
                                        <i class="font-green fa fa-check-circle"></i>
                                        {{ jobqueue[col.field] | titlecase }}
                                    </ng-container>
                                    <ng-container *ngIf="jobqueue['jobStatus'] === 'ERROR'">
                                        <i class="font-red fa fa-exclamation-circle"></i>
                                        {{ jobqueue[col.field] | titlecase }}
                                    </ng-container>
                                </ng-container>
                            </ng-template>

                            <ng-template #fourth>
                                <ng-container *ngIf="col.field === 'jobTypeDescription'; else fifth">
                                    {{ submittedJobTypeTitle}}
                                </ng-container>
                            </ng-template>

                            <ng-template #fifth>
                                <ng-container *ngIf="col.field === 'masterCommunityName'; else sixth">
                                    {{ masterCommunityInfo}}
                                </ng-container>
                            </ng-template>
                            <ng-template #sixth>
                                <ng-container> {{ jobqueue[col.field] | titlecase }}</ng-container>
                            </ng-template>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr *ngIf="showNoResultsMessage; else othermessage">
                        <td [attr.colspan]="columns.length+1">
                            No tracking information found for this job.
                        </td>
                    </tr>
                    <ng-template #othermessage>
                        <tr *ngIf="showImportCommunitiesMessage">
                            <td [attr.colspan]="columns.length+1">
                                No tracking information found. This is an auto-job that runs every 3 hours.
                            </td>
                        </tr>
                    </ng-template>
                </ng-template>
            </p-table>
        </div>
    </div>
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

    <p-dialog position="center" header="Error Message for Job Ending at {{ updatedTimeStamp | date: 'short' }}"
        [(visible)]="showErrorModal" [draggable]="true" resizable="true" [modal]="true" [style]="{'max-width':'75%'}">
        <h4>{{ errorDivision }} - {{ errorMasterCommunityName }}</h4>
        <p class="p-m-0">
            {{ modalErrorMessageString }}
        </p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" (click)="refreshErrorModal()" label="Close" class="p-button-text"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Tracking Information for {{ selectedMstrCommId }} with JobQueueId: {{ selectedJobQueueId }}"
        [(visible)]="isPopupVisible" [modal]="true" [draggable]="true" resizable="true" [style]="{'max-width':'100%'}"
        (onHide)="onDialogClose()">
        <ng-template pTemplate="content">
            <div *ngIf="isPopupVisible">
                <p-table #dt styleClass="p-datatable-striped" [columns]="popupCol" [value]="jobTrackInfo"
                    [paginator]="false" [rows]="10" [responsive]="true" [loading]="isLoading">
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngFor="let col of popupCol" [ngStyle]="{'width': col.width}">
                                {{ col.header }}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-jobTrack>
                        <tr>
                            <td *ngFor="let col of popupCol" style="overflow: hidden;">
                                <ng-container *ngIf="col.field === 'startTime'; else second">
                                    {{jobTrack[col.field] | date: 'MM-dd-yyyy ' }}{{jobTrack[col.field] | date:
                                    'shortTime'
                                    }}
                                </ng-container>
                                <ng-template #second>
                                    <ng-container> {{ jobTrack[col.field] | titlecase }}</ng-container>
                                </ng-template>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" (click)="onDialogClose()" label="Close" class="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
    <p-toast position="top-right"></p-toast>