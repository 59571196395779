export class ReportDefinitionAndDetails {
    reportId: string;
    reportName: string;
    reportDesc: string;
    reportRequestForm: string;
    reportDisplayOrder: number;
    sortColumn: string;
    reportDataSource: string;
    ssrsReportName: string;
}

export class LapReportRequest {
    reportRequestId: number;
    reportId: string;
    sessionId: string;
    requestDateTime: Date;
    scenarioId: number;
    mstrCommId: number;
    orgLvlCd: string;
    allScenarioTypeCd: string;
    mthDtlInd: string;
    qtrDtlInd: string;
    wifScenarioId: number;
    lapMInd: string;
    allScenarioInd: string;
    origScenarioInd: string;
    sortColumn: string;
    insUserId: string;
    insDt: Date;
    updtUserId: string;
    updtDt: Date;
    commId: number;
    communities: string;
    embedUrl: string;
}

export class LapScenario {
    scenarioId: number;
    scenarioName: string;
    scenarioTypeCd: string;
}

export class MstrCommAndCommAndScenario {
    commId: number;
    commName: number;
    mstrCommId: number;
    scenarioId: number;
    scenarioName: string;
    scenarioTypeCd: string;
}

export class ReportCommunity {
    mstrCommId: number;
    commId: number;
    commName: string;
}

export class SsrsReportParam {
    reportServer: string;
    reportUrl: string;
    showParameters: string;
    reportId: string;
    reportRequestId: number;
    userId: string;
    refresh: number;
    language: string;
    width: number;
    height: number;
    toolbar: string;
}
