<div *ngIf="!checkAdminPage()">
    <div class="panel site-banner {{siteBanner.bannerType}}" *ngIf="siteBanner.isDisplayed">
        <div class="banner-header">
            <div> <i class="fa fa-flag fa-lg"></i>&nbsp;{{siteBanner.bannerHeader}}
                <span class="pull-right">
                    <a data-toggle="collapse" data-target="#banner-text-area">
                        <span class="hideShowLabel" *ngIf="displayClass == 'in'" (click)="setHidden(true)">
                            <i id="minus" class="fa fa-minus">&nbsp;</i>Hide
                        </span>
                        <span class="hideShowLabel" *ngIf="displayClass == ''" (click)="setHidden(false)">
                            <i id="plus" class="fa fa-plus">&nbsp;</i>Show
                        </span>
                    </a>
                    <span class="userNameArea">
                        {{ username }}
                    </span>
                </span>
            </div>
        </div>
        <br>
        <div class="row banner-text collapse {{displayClass}}" id="banner-text-area">
            <div class="col-sm-12">
                {{siteBanner.bannerText}}
            </div>
        </div>
    </div>
</div>