export class JobQueue {
    jobQueueId: string;
    jobStatus: string;
    jobCreatedDate: Date;
    jobStartedDate: Date;
    jobDateUpdated?: Date;
    submittedBy: string;
    division: number;
    divisionName: string;
    masterCommunity?: number;
    masterCommunityName: string;
    jobType: number;
    jobTypeDescription: string;
    paramJsonModel: string;
    jobStatusDescription: string;
}

export class JobQueueTracking {
    jobQueueId: string;
    startDate: Date;
    taskDescription: string;
    masterCommunity?: number;

}

export class JobQueueParam {
    mstrCommId: number;
    jobType: number;
    submittedByUser: string;
}

export class Subsites {
    title: string;
    url: string;
    mstrCommId: number;
}

export class CreateBookOptions {
    fullBook: boolean;
    execBook: boolean;
    preFeasyBook: boolean;
    bBook: boolean;
}

export class JobTriggerParam {
    isAutoJobActive: boolean;
    isProcessorActive: boolean;
}
