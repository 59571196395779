import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { SiteBanner } from '../../models';
import { AuthorizationService, SiteBannerService } from '../../services';

@Component({
    selector: 'app-site-banner',
    templateUrl: './site-banner.component.html',
    styleUrls: ['./site-banner.component.css']
})
export class SiteBannerComponent implements OnInit {

    @Input() isAdminPage: boolean = false;
    @Input() isManager: boolean = false;
    @Input() siteBannerInput: SiteBanner = new SiteBanner();
    @Output() bannerEvent = new EventEmitter<boolean>();

    currentRetryCount: number = 0;
    currentRoute: string = null;
    displayClass: string;
    isLoading: boolean = false;
    maxRetries: number = 20; // 20 * 500ms = 10 seconds
    siteBanner: SiteBanner;

    get username(): string {
        return this.authService.getUsername();
    }

    constructor(private authService: AuthorizationService, private router: Router, private siteBannerService: SiteBannerService) { }

    ngOnInit(): void {
        this.currentRetryCount = 0;
        this.siteBanner = new SiteBanner();
        if (this.isManager) {
            this.siteBanner = this.siteBannerInput;
            this.displayClass = 'in';
        } else {
            this.displayClass = '';
            this.getSiteBannerInfo();
        }
    }

    checkAdminPage(): boolean {
        if (this.isManager) {
            return false;
        } else {
            this.currentRoute = this.router.url.replace("/", "");
            return this.currentRoute?.toLowerCase().indexOf("admin") > -1;
        }
    }

    getSiteBannerInfo(): void {
        // Retry loop is needed to ensure that the user is authenticated before making the call
        // Effective for high latency/low bandwidth connections
        if (this.authService.authenticated() != true && this.currentRetryCount < this.maxRetries) {
            this.currentRetryCount++;
            setTimeout(() => {
                this.getSiteBannerInfo();
            }, 500);
        }
        else {
            this.currentRetryCount = 0;
            this.siteBannerService.getSiteBannerInfo().subscribe((data) => {
                this.siteBanner = data;
                this.bannerEvent.emit(this.siteBanner.isDisplayed);
            });
        }
    }

    setHidden(status: boolean): void {
        this.siteBannerService.userHasClosed = status;
        if (this.siteBannerService.userHasClosed) {
            this.displayClass = '';
        } else {
            this.displayClass = 'in';
        }
    }
}
