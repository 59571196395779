<p-dialog position="center" header="Edit Org Level - {{selectedOrgLevel}}" [(visible)]="showEditOrgModal" [draggable]="true" resizable="true" [modal]="true" [style]="{'max-width':'75%'}">
    <h4>Edit {{selectedOrgLevel}}</h4>

<!-- ***** Area ***** -->
    <div *ngIf="selectedOrgLevel == 'Area'">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="areaId">Area Id</label>
                <input type="text" class="form-control" id="areaId" disabled="true" [(ngModel)]="selectedOrgObject.areaId">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="areaName">Area Name</label>
                <input type="text" class="form-control" id="areaName" [maxlength]="50" [(ngModel)]="selectedOrgObject.areaName">
            </div>
        </div>
    </div>
<!-- ***** County ***** -->
    <div *ngIf="selectedOrgLevel == 'County'">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="countyId">County Id</label>
                <input type="text" class="form-control" id="countyId" disabled="true" [(ngModel)]="selectedOrgObject.countyId">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="countyName">County Name</label>
                <input type="text" class="form-control" id="countyName" [maxlength]="50" [(ngModel)]="selectedOrgObject.countyName">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="stateCd">State Code</label>
                <input type="text" class="form-control" id="stateCd" [maxlength]="2" [(ngModel)]="selectedOrgObject.stateCd">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="fipsStateCd">FIPS State Code</label>
                <input type="text" class="form-control" id="fipsStateCd" [maxlength]="2" [(ngModel)]="selectedOrgObject.fipsStateCd">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="fipsCountyCd">FIPS County Code</label>
                <input type="text" class="form-control" id="fipsCountyCd" [maxlength]="3" [(ngModel)]="selectedOrgObject.fipsCountyCd">
            </div>
        </div>
    </div>
<!-- ***** Division ***** -->
    <div *ngIf="selectedOrgLevel == 'Division'">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="divId">Division Id</label>
                <input type="text" class="form-control" id="divId" disabled="true" [(ngModel)]="selectedOrgObject.divId">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="divName">Division Name</label>
                <input type="text" class="form-control" id="divName" [maxlength]="50" [(ngModel)]="selectedOrgObject.divName">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="area">Area</label>
                <select class="form-control" id="area" [(ngModel)]="selectedOrgObject.areaId">
                    <option *ngFor="let area of dropdownAreas" [value]="area.areaId">
                        {{ area.areaName }}
                    </option>
                </select>
                <span *ngIf="isLoading">
                    <img src="../../../assets/loading_spinner-alternate.gif" alt=" Loading...">
                </span>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="supportDivInd">Support Division Indicator</label>
                <input type="checkbox" class="form-control" id="supportDivInd" [(ngModel)]="selectedOrgObject.supportDivInd">
            </div>
        </div>
    </div>
<!-- ***** Market ***** -->
    <div *ngIf="selectedOrgLevel == 'Market'">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="mktId">Market Id</label>
                <input type="text" class="form-control" id="mktId" disabled="true" [(ngModel)]="selectedOrgObject.mktId">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="mktName">Market Name</label>
                <input type="text" class="form-control" id="mktName" [maxlength]="100" [(ngModel)]="selectedOrgObject.mktName">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="lawsonMktNbr">Lawson Market Number</label>
                <input type="text" class="form-control" id="lawsonMktNbr" [maxlength]="4" [(ngModel)]="selectedOrgObject.lawsonMktNbr">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="division">Division</label>
                <select class="form-control" id="division" [(ngModel)]="selectedOrgObject.divId">
                    <option *ngFor="let div of dropdownDivisions" [value]="div.divId">
                        {{ div.divName }}
                    </option>
                </select>
                <span *ngIf="isLoading">
                    <img src="../../../assets/loading_spinner-alternate.gif" alt=" Loading...">
                </span>
            </div>
        </div>
    </div>
<!-- ***** SubMarket ***** -->
    <div *ngIf="selectedOrgLevel == 'SubMarket'">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="subMktId">SubMarket Id</label>
                <input type="text" class="form-control" id="subMktId" disabled="true" [(ngModel)]="selectedOrgObject.subMktId">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="subMktName">SubMarket Name</label>
                <input type="text" class="form-control" id="subMktName" [maxlength]="100" [(ngModel)]="selectedOrgObject.subMktName">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="market">Market</label>
                <select class="form-control" id="market" [(ngModel)]="selectedOrgObject.mktId">
                    <option *ngFor="let mkt of dropdownMarkets" [value]="mkt.mktId">
                        {{ mkt.mktName }}
                    </option>
                </select>
                <span *ngIf="isLoading">
                    <img src="../../../assets/loading_spinner-alternate.gif" alt=" Loading...">
                </span>
            </div>
        </div>
    </div>
<!-- ***** Universal ***** -->
    <div class="row"></div>
    <div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="insUserId">Created By</label>
                <input type="text" class="form-control" id="insUserId" disabled="true" [(ngModel)]="selectedOrgObject.insUserId">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="insDt">Created Date</label>
                <input type="text" class="form-control" id="insDt" disabled="true" [(ngModel)]="selectedOrgObject.insDt">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="updtUserId">Updated By</label>
                <input type="text" class="form-control" id="updtUserId" disabled="true" [(ngModel)]="selectedOrgObject.updtUserId">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label" for="updtDt">Updated Date</label>
                <input type="text" class="form-control" id="updtDt" disabled="true" [(ngModel)]="selectedOrgObject.updtDt">
            </div>
        </div>
    </div>
    
    <ng-template pTemplate="footer">
        <div>
            <button type="button" class="btn btn-primary" (click)="onSaveOrg()">
                <i class="fa fa-floppy-o"></i>&nbsp; Save
            </button>
            &nbsp;
            <button type="button" class="btn btn-danger" (click)="onDeleteOrg()">
                <i class="fa fa-trash-o"></i>&nbsp; Delete {{selectedOrgLevel}}
            </button>
            &nbsp;
            <button type="button" class="btn btn-secondary" (click)="onEditModalClose()">
                <i class="fa fa-times-circle"></i>&nbsp; Close
            </button>
            
        </div>
    </ng-template>
</p-dialog>
