<p-toast position="top-right"></p-toast>

<div class="well panel">
    <h2> <i class="fa fa-map fa-lg"></i> Org Manager</h2>
    <br>
    <div class="row">
        <div class="form-group col-sm-12">
            <label class="control-label">Org Level </label>
            <select [(ngModel)]="selectedOrgLevel" class="form-control" [disabled]="isLoading" (change)="onOrgLevelChange($event.target.value)">
                <option [disabled]="true" [selected]="true" [value]="0" >Select an Org Level...</option>
                <option *ngFor="let org of orgLevels" [value]="org">
                    {{ org }}
                </option>
            </select>
        </div>
    </div>
    <div class="button-area">
        <button type="button" class="btn btn-primary" (click)="onRefreshClick()" [disabled]="selectedOrgLevel == '0' || isLoading">
            <i class="fa fa-search"></i>&nbsp; Refresh
        </button>
        &nbsp;
        <button type="button" class="btn btn-success" (click)="onAddOrgClick()">
            <i class="fa fa-plus"></i>&nbsp; Add <span *ngIf="selectedOrgLevel !== '0'">{{selectedOrgLevel}}</span>
        </button>
        &nbsp;
        <span *ngIf="isLoading">
            <img width="30" height="30" src="../../../assets/loading_spinner-alternate.gif" alt=" Loading...">
        </span>
    </div>
</div>
<div class="well panel" *ngIf="orgData.length > 0">
    <h2> <i class="fa fa-compass fa-lg"></i>{{selectedOrgLevel}}s</h2>
    <br>
    <p-table #dt styleClass="p-datatable-striped" [columns]="cols" [value]="orgData" [paginator]="false" [rows]="10" [responsive]="true" [loading]="loading">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-org let-columns="columns">
            <tr>
                <td *ngFor="let col of columns" style="overflow: hidden;">
                    <ng-container *ngIf="col.type === 'date'; else second">{{ org[col.field] | date:'M/d/yy, h:mm:ss a' }}</ng-container>
                    <ng-template #second>
                        <ng-container *ngIf="col.field === 'edit'; else third">
                            <button class="btn btn-primary" (click)="onEditButtonClick(org)">
                                <i class="fa fa-pencil-square-o fa-sm"></i> Edit
                            </button>
                        </ng-container>
                    </ng-template>
                    <ng-template #third>
                        <ng-container *ngIf="col.field === 'supportDivInd'; else fourth">
                            {{ org[col.field] == true ? 'Yes' : 'No' }}
                        </ng-container>
                    </ng-template>
                    <ng-template #fourth>
                        <ng-container>
                            {{ org[col.field] }}
                        </ng-container>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-edit-org *ngIf="showEditOrgModal" (modalClose)="onEditModalClose($event)" [selectedOrgLevel]="selectedOrgLevel" [selectedOrgObject]="selectedOrgObject" [showEditOrgModal]="showEditOrgModal"></app-edit-org>
<app-add-org *ngIf="showAddOrgModal" (modalClose)="onAddModalClose($event)" [orgLevels]="orgLevels" [selectedOrgLevel]="selectedOrgLevel" [showAddOrgModal]="showAddOrgModal"></app-add-org>
