import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { LapReportRequest } from '../models';
import { SharedService } from '.';

@Injectable()
export class ReportService {
    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getEmbedInfo(reportName): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Report/';
        return this.http.get(`${getUrl}GetPowerBiData(${reportName})`, opts);
    }

    getReportDefinitionsList(): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Report/';
        return this.http.get(`${getUrl}GetReportDefinitions`, opts);
    }

    getSsrsReportName(reportid): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Report/';
        this.http.get(`${getUrl}GetSsrsReportName(${reportid})`, opts).subscribe((returnVal) => {
            return returnVal;
        });
    }

    getReportsRequestList(): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Report/';
        return this.http.get(`${getUrl}GetReportRequest`, opts);
    }

    getReportRequestListById(id: number): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Report/';
        return this.http.get(`${getUrl}ReportRequestById(${id})`, opts);
    }

    getReportRequestListBySessionId(sessionId: string): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Report/';
        return this.http.get(`${getUrl}ReportRequestBySessionId(${sessionId})`, opts);
    }

    getScenariosByMultipleCommunities(communities: string): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Report/';
        return this.http.get(`${getUrl}GetScenariosByCommunities(${communities})`, opts);
    }

    postReportRequest(reportRequest: LapReportRequest): any {
        const opts = this.sharedService.getOptsJson();
        const postUrl = environment.baseUrl + '/Report/';
        //  have to re create the model then call on stringify
        //  stringify converts number 4++ digits long to string so it ends up
        //  not matching the API call param signature and not making it
        //  into the API!
        const data = JSON.stringify({
            reportRequestId: Number(reportRequest.reportRequestId),
            reportId: reportRequest.reportId,
            sessionId: reportRequest.sessionId,
            requestDateTime: reportRequest.requestDateTime,
            scenarioId: Number(reportRequest.scenarioId),
            mstrCommId: Number(reportRequest.mstrCommId),
            orgLvlCd: reportRequest.orgLvlCd,
            allScenarioTypeCd: reportRequest.allScenarioTypeCd,
            mthDtlInd: reportRequest.mthDtlInd,
            qtrDtlInd: reportRequest.qtrDtlInd,
            wifScenarioId: Number(reportRequest.wifScenarioId),
            lapMInd: reportRequest.lapMInd,
            allScenarioInd: reportRequest.allScenarioInd,
            origScenarioInd: reportRequest.origScenarioInd,
            sortColumn: reportRequest.sortColumn,
            insUserId: reportRequest.insUserId,
            insDt: reportRequest.insDt,
            updtUserId: reportRequest.updtUserId,
            updtDt: reportRequest.updtDt,
            commId: Number(reportRequest.commId),
            communities: reportRequest.communities
        });

        return this.http.post(`${postUrl}PostReportRequest`, data, opts);
    }

    getLapScenarioList(): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Report/';
        return this.http.get(`${getUrl}GetScenarios`, opts);
    }

    getLapScenarioListByCommId(commId: number): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Report/';
        return this.http.get(`${getUrl}GetScenariosByCommId(${commId})`, opts);
    }

    getReportCommunityByMstrCommId(mstrCommId: number): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Report/';
        return this.http.get(`${getUrl}GetReportCommunityByMstrCommId(${mstrCommId})`, opts);
    }
}
