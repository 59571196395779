import { Component, OnInit, ViewChild } from '@angular/core';

import { AuthorizationService } from '../../services';
import { JobQueueListComponent } from '../../shared';

@Component({
    selector: 'app-job-queue-manager',
    templateUrl: './job-queue-manager.component.html',
    styleUrls: ['./job-queue-manager.component.css']
})
export class JobQueueManagerComponent implements OnInit {
    @ViewChild(JobQueueListComponent)
    jobQueueChild: JobQueueListComponent;

    get username(): string {
        return this.authService.getUsername();
    }

    constructor(private authService: AuthorizationService) { }

    ngOnInit(): void {

    }
}
