import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ImportAnaplanParam } from '../models';
import { SharedService } from '.';

@Injectable()
export class AnaplanService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    anaplanDivisionCommunities(lawsonNumbers: string[]): Observable<any> {
        const opts = this.sharedService.getOptsJson();
        const getUrl = environment.baseUrl + '/Anaplan/AnaplanDivisionCommunities';
        return this.http.post(getUrl, lawsonNumbers, opts);
    }

    createAnaplanImport(createAnaplanImportParam: ImportAnaplanParam): Observable<any> {
        const opts = this.sharedService.getOptsJson();
        const postUrl = environment.baseUrl + '/Anaplan/';
        const data = JSON.stringify({
            mstrCommId: createAnaplanImportParam.mstrCommId,
            feasyCode: createAnaplanImportParam.feasibilityCode
        });
        return this.http.post(`${postUrl}CreateMasterCommunity`, data, opts);
    }

    getAnaplanCommunities(): Observable<any> {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Anaplan/GetAnaplanCommunities';
        return this.http.get(getUrl, opts);
    }

    getAnaplanExports(): Observable<any> {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Anaplan/GetAnaplanExports';
        return this.http.get(getUrl, opts);
    }

    getAnaplanFeasyMasterCommunity(feasyCode: string): Observable<any> {
        const subs = 'feasyCode=' + feasyCode;
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/Anaplan/GetAnaplanFeasyMasterCommunity?feasyCode=(' + encodeURIComponent(feasyCode) + ')';
        return this.http.post(getUrl, subs, opts);
    }

    // to get last imported date for refresh links
    getAnaplanFeasyMasterCommunityById(commId: string): Observable<any> {
        const opts = this.sharedService.getOpts();
        const subs = 'commId=' + commId;
        const getUrl = environment.baseUrl + '/Anaplan/GetAnaplanFeasyMasterCommunityById?commId=' + commId;
        return this.http.post(getUrl, subs, opts);
    }
}
