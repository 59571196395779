<div class="well panel">
    <h2> <i class="fa fa-book fa-lg"></i> Create Book</h2>
    <br>
    <div class="">
        <select id="mastercomm" class="form-control" (change)="onCommunitySiteChange($event.target.value)"
                [disabled]="CommunitySites === null">
            <option [disabled]="true" [value]=-1>Select a Feasibility Site...</option>
            <option *ngFor="let site of CommunitySites" [value]="site.mstrCommId">{{site.title}}</option>
        </select>
    </div>
    <br>
    <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" (change)="checkButtonReady()" [(ngModel)]="bookOptions.fullBook" id="fullbook">
                <label class="normal-font-weight" for="fullbook">&nbsp;Create Full Book</label>
            </div>
            <div class="form-check">
                <input type="checkbox" [(ngModel)]="bookOptions.execBook" (change)="checkButtonReady()" id="execbook">
                <label class="normal-font-weight" for="execbook">&nbsp;Create Exec Copy Book</label>
            </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="form-check">
                <input type="checkbox" [(ngModel)]="bookOptions.preFeasyBook" (change)="checkButtonReady()" id="prefeasybook">
                <label class="normal-font-weight" for="prefeasybook">&nbsp;Create Pre-Feasy Book</label>
            </div>
            <div class="form-check">
                <input type="checkbox" [(ngModel)]="bookOptions.bBook" (change)="checkButtonReady()" id="Bbook">
                <label class="normal-font-weight" for="Bbook">&nbsp;Create B Book (Section 1-9)</label>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-3">
            <button type="button" class="btn btn-primary" (click)="onCreateBookClick()" [disabled]="!isButtonReady">
                <i class="fa fa-bookmark-o"></i> Create Books
            </button>
            &nbsp;&nbsp;&nbsp;
            <span *ngIf="isLoading">
                <img  width="30" height="30" src="../../assets/loading_spinner-alternate.gif" alt=" Loading...">
            </span>
        </div>
    </div>
</div>

<p-toast position="top-right"></p-toast>

<app-job-queue-list></app-job-queue-list>
