import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { models } from 'powerbi-client';
import { IPaginatedReportLoadConfiguration } from 'powerbi-models';
import * as pbi from 'powerbi-client';
import { MessageService } from 'primeng/api';

import {
    AnaplanCommunity, CreateSiteParam, JobQueueParam, LapReportRequest, MstrCommAndCommAndScenario,
    ReportCommunity, ReportDefinitionAndDetails, SsrsReportParam
} from '../models';
import { AnaplanService, AuthorizationService, JobQueueService, ReportService, SiteService } from '../services';
import { JobQueueListComponent } from '../shared';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
    jobQueueChild: JobQueueListComponent;

    dropdownSettings = {};
    importBtnDisabled: boolean = true;
    lapScenario: number = -1;
    pReportId: string;
    pReportRequestId: number;
    pReportUrl: string;
    pUserId: string;
    runReport: boolean = false;

    createJobErrorMessage: string = '';
    dateImported: string;
    includeMonthTotal: boolean;
    includeSummary: boolean = true;
    isLoading: boolean;
    mstrCommId: number;
    runCurrentScenarioBtnSelected: boolean;
    runRptBttnDisabled: boolean;
    scenarioDropDownDisabled: boolean;
    scenarioEnabled: boolean;
    showImported: boolean;
    showJobQueue: boolean;
    showNotImported: boolean;
    scenarioFlag: string;
    selComIdList: string = '';
    selFeasyCode: string;
    selMstrCommId: number;
    selAnaplanCommunity: any;

    anaplanFeasyList: AnaplanCommunity[] = [];
    embedToken: string;
    lapScenarioList: MstrCommAndCommAndScenario[] = [];
    powerBIReportId: string;
    powerBIEmbedUrl: string;
    preSelectedCommunities: ReportCommunity[] = [];
    reportCommuntiyList: ReportCommunity[] = [];
    reportDefinitionAndDetailList: ReportDefinitionAndDetails[] = [];
    reportRequestList: LapReportRequest[] = [];
    selReportCommunityList: ReportCommunity[] = [];
    selReportParameters: LapReportRequest = new LapReportRequest();
    selSsrsReportParam: SsrsReportParam = new SsrsReportParam();

    get username(): string {
        return this.authService.getUsername();
    }

    constructor(private anaplanService: AnaplanService, private authService: AuthorizationService, private jobQueueService: JobQueueService,
        private messageService: MessageService, private reportService: ReportService, private siteService: SiteService) { }

    ngOnInit(): void {
        this.showNotImported = false;
        this.showImported = false;
        this.isLoading = true;
        this.showJobQueue = false;
        this.importBtnDisabled = true;
        this.scenarioDropDownDisabled = true;
        this.runCurrentScenarioBtnSelected = false;
        this.includeMonthTotal = false;

        this.anaplanService.anaplanDivisionCommunities(this.authService.getRoles()).subscribe((recordset: any[]) => {
            this.anaplanFeasyList = recordset;
            this.isLoading = false;
        }, err => {
            console.log(err);
        });

        // get the report definitions list!
        this.getReports();

        // disable some controls by default on init...
        this.disableControlsByDefault();

        this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 20,
            allowSearchFilter: true
        };
    }

    get disableImportAnaplanCommunities(): boolean {
        const jobAlreadyAdded = (element) => element.jobTypeDescription == "IMPORT ANAPLAN COMMUNITIES" && (element.jobStatus == "IN-QUEUE" || element.jobStatus == "IN-PROGRESS");
        return (this.jobQueueChild?.jobQueueList.length > 0 && this.jobQueueChild?.jobQueueList.findIndex(jobAlreadyAdded) > -1);
    }

    hideShowJobQueue(): void {
        if (this.showJobQueue === true) {
            this.showJobQueue = false;
        } else if (this.showJobQueue === false) {
            this.showJobQueue = true;
        }
    }

    getAccessToken(reportName: string): any {
        this.isLoading = true;
        this.reportService.getEmbedInfo(reportName).subscribe((data: any) => {
            this.isLoading = false;
            this.embedToken = data.token;
            this.powerBIReportId = data.id;
            this.powerBIEmbedUrl = data.embedUrl;
            this.checkRunReportButton();
        }, err => {
            console.log(err);
            this.isLoading = false;
        });
    }

    getReports(): void {
        this.isLoading = true;
        this.reportService.getReportDefinitionsList().subscribe((recordset) => {
            this.reportDefinitionAndDetailList = recordset;
            this.isLoading = false;
        }, err => {
            console.log(err);
            this.isLoading = false;
        });
    }

    getSsrsReportNameScalar(reportId): any {
        this.isLoading = true;
        this.reportService.getSsrsReportName(reportId).subscribe((scalar) => {
            this.isLoading = false;
            return scalar;
        }, err => {
            console.log(err);
            this.isLoading = false;
        });
    }

    getLapScenariosByCommId(commId): void {
        this.isLoading = true;
        this.reportService.getLapScenarioListByCommId(commId).subscribe((recordset) => {
            this.lapScenarioList = recordset;
            this.isLoading = false;
        }, err => {
            console.log(err);
            this.isLoading = false;
        });
    }

    getScenariosByMultipleCommunities(communities): void {
        this.isLoading = true;

        this.reportService.getScenariosByMultipleCommunities(communities).subscribe((recordset) => {
            this.lapScenarioList = recordset;

            if (this.lapScenarioList === null || this.lapScenarioList.length === 0) {
                this.runRptBttnDisabled = true;
                this.showNotImported = true;
                this.showImported = false;
            }

            this.onScenarioChange(this.lapScenarioList[0]?.scenarioId, this.lapScenarioList[0]?.scenarioName);
            this.checkRunReportButton();
            this.isLoading = false;
        }, err => {
            console.log(err);
            this.isLoading = false;
        });
    }

    getReportCommByMstrCommId(mstrCommId): void {
        this.isLoading = true;

        this.reportService.getReportCommunityByMstrCommId(mstrCommId).subscribe((recordset) => {
            this.reportCommuntiyList = recordset;

            const CommList = [];            
            for (let i = 0; i < recordset.length; i++) {
                CommList.push({ item_id: recordset[i].commId, item_text: recordset[i].commName });
            }

            this.reportCommuntiyList = CommList;
            this.selReportCommunityList = this.reportCommuntiyList;
            this.preSelectedCommunities = this.reportCommuntiyList;

            if (this.preSelectedCommunities.length > 0) {
                this.scenarioDropDownDisabled = false;
                this.runCurrentScenarioBtnSelected = true;
                this.onRunScenarioChange('single');
                this.lapScenario = 2;
                this.onScenarioChange(2, null);
                this.includeMonthTotal = true;
                this.onPeriodChange('month');
            }

            this.isLoading = false;
        }, err => {
            console.log(err);
            this.isLoading = false;
        });
    }

    onMasterCommChange(val): void {
        this.importBtnDisabled = true;
        this.selReportParameters.communities = '';
        this.reportCommuntiyList = [];
        this.preSelectedCommunities = [];
        this.scenarioDropDownDisabled = true;
        this.runCurrentScenarioBtnSelected = false;
        this.includeMonthTotal = false;
        this.resetScenario();
        this.selAnaplanCommunity = val;
        this.selFeasyCode = val;
        this.showNotImported = false;
        this.showImported = true; 

        this.anaplanService.getAnaplanFeasyMasterCommunity(val).subscribe((recordset) => {
            if (!recordset[0]) {
                this.showNotImported = true;
                this.showImported = false;
            } else {
                val = recordset[0].mstrCommId;

                let date = recordset[0].dateCreated.toString();
                const splitDate = date.split('T');
                date = splitDate[0] + ' ' + splitDate[1] + ' UTC';
                const dateCreated = new Date(date);
                this.dateImported = dateCreated.toString();                                        
            }

            if (val === -1) {
                this.selReportParameters.mstrCommId = 0;
            } else {
                this.importBtnDisabled = false;
                this.selReportParameters.mstrCommId = val;
                this.getReportCommByMstrCommId(this.selReportParameters.mstrCommId);
                this.selComIdList = '';
            }

            this.selReportParameters.commId = 0;

        }, err => {
            console.log(err);
        });

        this.checkRunReportButton();
    }

    onMasterCommunityRefresh(val): void {
        if (!val) {
            return;
        } // exit if no community selected

        this.anaplanService.getAnaplanFeasyMasterCommunity(val).subscribe((recordset) => {
            if (!recordset[0]) {
                this.showNotImported = true;
                this.showImported = false;
            } else {
                val = recordset[0].mstrCommId;
                this.dateImported = recordset[0].dateCreated;

                this.showNotImported = false;
                this.showImported = true;
            }
        }, err => {
            console.log(err);
        });

        if (val === -1) {
            this.selReportParameters.mstrCommId = 0;
            this.importBtnDisabled = true;
        } else {
            this.importBtnDisabled = false;           
            this.getReportCommByMstrCommId(this.selReportParameters.mstrCommId);
        }

        this.checkRunReportButton();
    }

    onCommunityChange(commId): void {
        if (commId === -1) {
            this.selReportParameters.commId = 0;
        } else {
            const selComId = (commId.item_id).toString();
            this.selComIdList += this.selComIdList === '' ? selComId : ',' + selComId;
            this.selReportParameters.communities = this.selComIdList;
        }

        this.getScenariosByMultipleCommunities(this.selReportParameters.communities);
    }

    onImportAnaplanData(): void {
        this.showJobQueue = false;
        // stub out mastercommid
        const csparam: CreateSiteParam = new CreateSiteParam();
        const feasyName = this.selAnaplanCommunity.split('^^');
        csparam.anaplanFeasyName = feasyName[0];
        csparam.anaplanFeasyId = feasyName[1];
        const user = this.username.split('@');
        this.siteService.createMasterCommunity(csparam).subscribe((result) => {
            this.mstrCommId = result;
            // dont run yet
            const param: JobQueueParam = new JobQueueParam();
            param.mstrCommId = this.mstrCommId;
            param.jobType = 7;
            param.submittedByUser = user[0].toLowerCase();
            this.jobQueueService.postJobQueue(param).subscribe(() => {
                param.jobType = 8;
                this.showJobQueue = true;
            });
        }, err => {
            console.log(err);
        });
    }

    onCommunitySelectAll(commIds: any[]): void {
        // ensure there are Communities selected to avoid errors
        if (commIds === null || commIds === undefined || commIds.length === 0) { return; }

        // tslint:disable-next-line: only-arrow-functions typedef
        const selCommIds = commIds.map(function (item) {
            return item.item_id;
        }).join(',');
        this.selReportParameters.communities = selCommIds;
        this.getScenariosByMultipleCommunities(this.selReportParameters.communities);
    }

    onCommIdDeSelect(commId): void {
        let removedCommId = commId.item_id.toString();
        this.selComIdList = this.selReportParameters.communities;
        if (this.selComIdList.length === 0) {
            this.selComIdList = '0';
            this.selReportParameters.communities = this.selComIdList;
            this.getScenariosByMultipleCommunities(this.selReportParameters.communities);
            return;
        }
        if ((this.selReportParameters.communities).indexOf(commId.item_id) === 0) {
            removedCommId = removedCommId + ',';
        } else {
            removedCommId = ',' + removedCommId;
        }
        this.selReportParameters.communities = (this.selReportParameters.communities).replace(removedCommId, '');
        this.getScenariosByMultipleCommunities(this.selReportParameters.communities);
        this.checkRunReportButton();
    }

    onAllCommDeSelect(): void {
        this.selReportParameters.communities = '0';
        this.getScenariosByMultipleCommunities(this.selReportParameters.communities);
    }

    onReportChange(report): void {
        this.selReportParameters.reportId = '';
        this.runRptBttnDisabled = true;
        if (report === -1) {
            this.selReportParameters.reportId = '';
            this.selSsrsReportParam.reportUrl = '';
            this.checkRunReportButton();
        } else {
            this.selReportParameters.reportId = report;
            const rst = this.getReportByReportId(this.selReportParameters.reportId);
            for (const p of rst) {
                this.selSsrsReportParam.reportUrl = p.ssrsReportName;
                this.getAccessToken(this.selSsrsReportParam.reportUrl);
            }
        }
    }

    onScenarioChange(scenario, scenarioName): void {
        this.selReportParameters.wifScenarioId = null;
        this.selReportParameters.scenarioId = null;
        if (scenario !== null) {
            this.selReportParameters.wifScenarioId = 0;
            const sname = scenarioName?.toLowerCase();
            if (sname && sname.indexOf('what-if') > -1) {
                this.selReportParameters.wifScenarioId = scenario;
            }
            if (scenario === -1) {
                this.selReportParameters.scenarioId = null;
            } else {
                this.selReportParameters.scenarioId = scenario;
            }
        }
        this.checkRunReportButton();
    }

    checkRunReportButton(): void {
        this.runRptBttnDisabled = true;

        if (this.selReportParameters.mstrCommId !== 0 &&
            this.selReportParameters.communities !== '' &&
            (this.selReportParameters.scenarioId !== 0 || this.selReportParameters.allScenarioInd === 'Y') &&
            this.selReportParameters.reportId !== '' &&
            this.selReportParameters.reportId !== undefined &&
            (this.selReportParameters.mthDtlInd === 'Y' || this.selReportParameters.qtrDtlInd === 'Y') &&
            (this.showImported)) {
            this.runRptBttnDisabled = false;
        }
        if (this.selReportParameters.scenarioId === null || this.selReportParameters.scenarioId === undefined) {
            this.runRptBttnDisabled = true;
            if (this.selReportParameters.allScenarioInd !== 'Y') {
                this.runRptBttnDisabled = true;
            } else {
                this.runRptBttnDisabled = false;
                if (this.selReportParameters.reportId === null ||
                    this.selReportParameters.reportId === undefined) {
                    this.runRptBttnDisabled = true;
                }
            }
        }
        if (this.selReportParameters.communities === '0' ||
            this.selReportParameters.communities === '' ||
            this.selReportParameters.communities === undefined) {
            this.runRptBttnDisabled = true;
        }
    }

    changeSSRS(): void {
        this.pReportUrl = 'LAPRpt_FeasySummary';
        this.pReportId = 'LAPRPT023';
        this.pReportRequestId = 3015;
        this.pUserId = 'FeasyApiUser';
    }

    onClick(): void {
        this.showJobQueue = false;
        this.changeSSRS();
        this.runReport = true;
    }

    checkIfIncludeSummary(event): void {
        if (event.checked) {
            this.selReportParameters.orgLvlCd = 'MSTRCOMM';
        } else {
            this.selReportParameters.orgLvlCd = 'COMM';
        }
    }

    onImportAnaplanCommunitiesClick(): void {
        this.createJobErrorMessage = '';  // reset error message        
        const user = this.username.split('@');

        const importParam: JobQueueParam = new JobQueueParam();
        importParam.mstrCommId = 0;
        importParam.jobType = 8;
        importParam.submittedByUser = user[0].toLowerCase();

        this.jobQueueService.postJobQueue(importParam).subscribe(() => {
            this.messageService.add({
                severity: 'success',
                summary: 'Added',
                detail: 'Import Anaplan Communities request added to queue'
            });

            this.jobQueueChild.getJobQueues();
        }, (err: HttpErrorResponse) => {
            if (err.status === 409) {
                // HTTP Conflict, Job Type Already Exists
                this.createJobErrorMessage = 'Import Anaplan Communities already scheduled';
            } else {
                console.log(err);
                this.isLoading = false;

                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: err.message,
                });
            }
        });
        this.showJobQueue = true;
    }

    onPeriodChange(period): void {
        if (period === 'month') {
            this.selReportParameters.qtrDtlInd = 'N';
            this.selReportParameters.mthDtlInd = 'Y';
        } else {
            this.selReportParameters.qtrDtlInd = 'Y';
            this.selReportParameters.mthDtlInd = 'N';
        }

        this.checkRunReportButton();
    }

    onRunReportClick(): void {
        this.runReport = true;
        const res = this.isValidReportRequestParams();

        if (res === '' || res === undefined) {
            let lst: any;
            this.reportService.postReportRequest(this.selReportParameters).subscribe((recordset) => {
                lst = recordset;
                this.selSsrsReportParam.reportRequestId = lst.reportRequestId;
                this.selSsrsReportParam.reportId = lst.reportId;
                this.selSsrsReportParam.userId = lst.insUserId;
                this.selSsrsReportParam.reportUrl = this.powerBIEmbedUrl;

                this.pReportUrl = this.selSsrsReportParam.reportUrl.trim();
                this.pReportId = this.selSsrsReportParam.reportId.trim();
                this.pReportRequestId = this.selSsrsReportParam.reportRequestId;
                this.pUserId = this.selSsrsReportParam.userId.trim();

                const isValidSsrs = this.isValidSsrsReportParams();
                if (!isValidSsrs) {
                    this.isLoading = false;
                    this.runReport = false;
                    return;
                }

                this.isLoading = false;

                let embedConfiguration: IPaginatedReportLoadConfiguration = {
                    accessToken: this.embedToken,
                    embedUrl: this.powerBIEmbedUrl,
                    id: this.powerBIReportId,
                    tokenType: models.TokenType.Embed,
                    type: 'report',
                    settings: {
                        commands: {
                            parameterPanel: {
                                enabled: false,
                                expanded: true
                            }
                        }
                    },
                    parameterValues: [
                        { name: "I_ReportId", value: this.pReportId },
                        { name: "I_ReportRequestId", value: this.pReportRequestId.toString() },
                        { name: "I_UserId", value: this.pUserId },
                        { name: "I_Refresh", value: "0" }
                    ]
                };
                // Get a reference to the HTML element that contains the embedded report.
                let embedContainer = document.getElementById('reportContainer');
                let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
                powerbi.reset(embedContainer);
                // Embed the report.
                powerbi.embed(embedContainer, embedConfiguration);
            }, err => {
                console.log(err);
                this.isLoading = false;
                this.runReport = false;
            });
        } else {
            return;
        }
    }

    onRunScenarioChange(runscenario): void {
        if (runscenario === '' || runscenario === undefined) {
            this.scenarioEnabled = false;
            this.scenarioFlag = 'all';
            this.selReportParameters.allScenarioInd = 'N';
            this.selReportParameters.origScenarioInd = 'N';
            this.selReportParameters.wifScenarioId = 0;
        } else if (runscenario === 'single') {
            this.scenarioEnabled = true;
            this.selReportParameters.allScenarioInd = 'N';
            this.selReportParameters.origScenarioInd = 'N';
            this.selReportParameters.wifScenarioId = 0;
            this.selReportParameters.allScenarioTypeCd = '';
            this.scenarioFlag = runscenario;
        } else if (runscenario === 'all') {
            this.scenarioEnabled = false;
            this.scenarioFlag = runscenario;
            this.selReportParameters.scenarioId = 1;
            this.selReportParameters.wifScenarioId = 0;
            this.selReportParameters.allScenarioInd = 'Y';
            this.selReportParameters.origScenarioInd = 'N';
            this.selReportParameters.allScenarioTypeCd = 'WORK';
            this.resetScenario();
        }
        this.checkRunReportButton();
    }

    generateGuid(): string {
        // tslint:disable-next-line: typedef only-arrow-functions
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line: no-bitwise triple-equals  one-variable-per-declaration
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    isValidReportRequestParams(): string {
        const retval = '';

        if (this.selReportParameters.reportRequestId === undefined) {
            this.selReportParameters.reportRequestId = 0;
        }

        if (this.selReportParameters.allScenarioInd === '' || this.selReportParameters.allScenarioInd === undefined) {
            this.selReportParameters.allScenarioInd = 'N';
        }

        if (this.selReportParameters.allScenarioTypeCd === undefined) {
            this.selReportParameters.allScenarioTypeCd = 'WORK';
        }

        if (this.selReportParameters.communities === undefined) {
            return 'community selections cannot be empty';
        }

        if (this.selReportParameters.orgLvlCd === '' || this.selReportParameters.orgLvlCd === undefined) {
            if (this.selReportParameters.communities !== undefined && !this.includeSummary) {
                this.selReportParameters.orgLvlCd = 'COMM';
            } else {
                this.selReportParameters.orgLvlCd = 'MSTRCOMM';
            }
        }

        if (this.selReportParameters.insUserId === '' || this.selReportParameters.insUserId === undefined) {
            const user = this.username.split('@');
            this.selReportParameters.insUserId = user[0]; // 'feasyapid';
        }

        if (this.selReportParameters.insDt === undefined) {
            this.selReportParameters.insDt = this.getDateNowUtc();
        }

        if (this.selReportParameters.updtUserId === '' || this.selReportParameters.updtUserId === undefined) {
            const user = this.username.split('@');
            this.selReportParameters.updtUserId = user[0];
        }

        if (this.selReportParameters.updtDt === undefined) {
            this.selReportParameters.updtDt = this.getDateNowUtc();
        }

        if (this.selReportParameters.requestDateTime === undefined) {
            this.selReportParameters.requestDateTime = this.getDateNowUtc();
        }

        if (this.selReportParameters.lapMInd === '' || this.selReportParameters.lapMInd === undefined) {
            this.selReportParameters.lapMInd = 'N';
        }

        if (this.selReportParameters.mstrCommId === 0 || this.selReportParameters.lapMInd === undefined) {
            return 'Master community selection cannot be empty';
        }

        if (this.selReportParameters.mthDtlInd === '' || this.selReportParameters.mthDtlInd === undefined) {
            return 'Period selection cannot be empty';
        }

        if (this.selReportParameters.qtrDtlInd === '' || this.selReportParameters.qtrDtlInd === undefined) {
            return 'Period selection cannot be empty';
        }

        if (this.selReportParameters.origScenarioInd === '' || this.selReportParameters.origScenarioInd === undefined) {
            this.selReportParameters.origScenarioInd = 'N';
        }

        if (this.selReportParameters.reportId === '' || this.selReportParameters.reportId === undefined) {
            return 'Report selection cannot be empty';
        }

        if (this.selReportParameters.sessionId === '' || this.selReportParameters.sessionId === undefined) {
            this.selReportParameters.sessionId = this.generateGuid();
        }

        if (this.selReportParameters.sortColumn === '' || this.selReportParameters.sortColumn === undefined) {
            this.selReportParameters.sortColumn = 'Default';
        }

        if (this.selReportParameters.scenarioId === undefined) {
            this.selReportParameters.wifScenarioId = 0;
            return undefined;
        }

        return retval;
    }

    isValidSsrsReportParams(): boolean {
        if (this.pReportId === '' || this.pReportId === undefined) {
            this.runReport = false;
            return false;
        }
        if (this.pReportRequestId === 0 || this.pReportRequestId === undefined) {
            this.runReport = false;
            return false;
        }
        if (this.pReportUrl === '' || this.pReportUrl === undefined) {
            this.runReport = false;
            return false;
        }
        if (this.pUserId === '' || this.pUserId === undefined) {
            this.runReport = false;
            return false;
        }
        this.runReport = true;
        return true;
    }

    getDateNowUtc(): Date {
        const now = new Date();
        return new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    }

    getReportByReportId(id): any {
        return this.reportDefinitionAndDetailList.filter(selModel => selModel.reportId === id);
    }

    disableControlsByDefault(): void {
        this.runRptBttnDisabled = true;
        this.scenarioEnabled = false;
    }

    resetScenario(): void {
        const scenarioDropDown = (document.getElementById('scenario')) as HTMLSelectElement;
        scenarioDropDown.selectedIndex = 0;
        this.onScenarioChange(-1, null);
        this.checkRunReportButton();
    }
}
