<p-toast position="top-right"></p-toast>

<div class="well panel">
    <h2> <i class="fa fa-flag fa-lg"></i> Site Banner Manager</h2>
    <br>
    <div class="row">
        <div class="form-group col-sm-12">
            <label class="control-label">Default Starter Buttons</label>
            <div class="button-area">
                <button type="button" class="btn btn-info" [class.infoSelected]="isInfoSelected"
                    (click)="onInformationClick()" [disabled]="isLoading">
                    <i class="fa fa-info-circle"></i>&nbsp; Information
                </button>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-warning" [class.warningSelected]="isWarningSelected"
                    (click)="onWarningClick()" [disabled]="isLoading">
                    <i class="fa fa-exclamation-circle"></i>&nbsp; Warning
                </button>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-danger" [class.errorSelected]="isErrorSelected"
                    (click)="onErrorClick()" [disabled]="isLoading">
                    <i class="fa fa-times-circle"></i>&nbsp; Error
                </button>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-success" [class.successSelected]="isSuccessSelected"
                    (click)="onSuccessClick()" [disabled]="isLoading">
                    <i class="fa fa-check-circle"></i>&nbsp; Success
                </button>
            </div>
            <hr class="rounded">
        </div>
    </div>
    <div class="row">
        <div class="form-group col-sm-2">
            <!-- <label class="control-label">Display Banner </label> -->
            <input type="checkbox" id="import" text="Display Banner" [(ngModel)]="siteBanner.isDisplayed"
                (change)="checkDisplay($event.target.checked)" />&nbsp;&nbsp;<b>Display Banner?</b>
        </div>
        <div class="form-group col-sm-10">
            <label class="control-label">Banner Type </label>
            <select [(ngModel)]="siteBanner.bannerType" class="form-control" [disabled]="isLoading"
                (change)="onBannerTypeChange($event.target.value)">
                <option [disabled]="true" [selected]="true" [value]="0">Select a Banner Type...</option>
                <option *ngFor="let type of bannerTypes" [value]="type.value">
                    {{ type.label }}
                </option>
            </select>
        </div>
        <div class="form-group col-sm-12">
            <label class="control-label">Banner Header </label>&nbsp;&nbsp;&nbsp;<i>*50 character limit</i>
            <input class="form-control" name="bannerText" id="bannerText" [(ngModel)]="siteBanner.bannerHeader"
                maxlength="50" />
        </div>
        <div class="form-group col-sm-12">
            <label class="control-label">Banner Text </label>&nbsp;&nbsp;&nbsp;<i>*500 character limit</i>
            <textarea cols="35" rows="3" class="form-control" name="bannerText" id="bannerText"
                [(ngModel)]="siteBanner.bannerText" maxlength="500"></textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-3">
            <label class="control-label">Last Updated By: </label><br>
            {{ siteBanner.lastUpdatedBy }}
        </div>
        <div class="col-sm-3">
            <label class="control-label">Last Updated Date: </label><br>
            {{ siteBanner.lastUpdatedDate | date: 'MM/dd/yyyy hh:mm a' }}
        </div>
    </div>
    <div class="button-area">
        <button type="button" class="btn btn-primary" (click)="onSubmitClick()" [disabled]="isLoading">
            <i class="fa fa-floppy-o"></i>&nbsp; Submit
        </button>
        &nbsp;&nbsp;
        <img src="../../assets/loading_spinner-alternate.gif" *ngIf="isLoading" alt=" Loading...">
    </div>
</div>
<div class="well panel" *ngIf="siteBanner.isDisplayed">
    <h2>Banner Example</h2>
    <br>
    <app-site-banner [siteBannerInput]="siteBanner" [isManager]="true"></app-site-banner>
</div>