import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';

import { JobQueue, JobStatDataAverageExecutionTime, JobTriggerParam } from '../../models';
import { environment } from '../../../environments/environment';
import { JobQueueService, JobStatsService  } from '../../services';

@Component({
    selector: 'app-job-queue-list',
    templateUrl: './job-queue-list.component.html',
    styleUrls: ['./job-queue-list.component.css']
})
export class JobQueueListComponent implements OnInit {
    @Input() IsAdminPage = false;

    col: any[];
    errorDivision: string = '';
    errorMasterCommunityName: string = '';
    estimatedWaitTime: number = 0;
    isCheckingTriggers: boolean = false;
    jobProcessorChecked: boolean = false;
    jobProcessorStatusButtonEnabled: boolean = false;
    jobProcessorStatus: boolean = false;
    jobProcessorStatusMessage: string = 'Job Processor is currently turned off! Please wait until an Admin turns it back on.';
    jobQueueList: JobQueue[] = [];
    jobStatData: any[] = [];
    jobStatDataAverageExecutionTime: JobStatDataAverageExecutionTime[] = [];
    lastCheckedJobs: Map<string, number> = new Map();
    loading: boolean = false;
    modalErrorMessageString: string = '';
    numOfJobs: number = 0;
    requiredJobQueueList: JobQueue[] = [];
    selectedTimeFrame: number = 30;
    showErrorModal: boolean = false;
    showCancelErrorModal: boolean = false;
    title: string = 'Job Queue List';
    updatedTimeStamp: Date = null;

    constructor(private jobQueueService: JobQueueService,
        private jobStatsService: JobStatsService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private primeNgConfig: PrimeNGConfig) { }

    ngOnInit(): void {
        this.loading = true;
        this.jobProcessorStatus = false;
        this.primeNgConfig.ripple = true;
        this.checkJobTriggerStatus();
        this.setAllButtonEnabled(true);
        this.col = [
            { field: 'masterCommunityName', header: 'Master Community - Division', width: '25%' },
            { field: 'jobTypeDescription', header: 'Type', width: '15%' },
            { field: 'submittedBy', header: 'Submitted By', width: '9%' },
            { field: 'jobStartedDate', header: 'Job Started', width: '9%' },
            { field: 'jobDateUpdated', header: 'Last Updated', width: '9%' },
            { field: 'jobStatus', header: 'Status', width: '12%' },
            { field: 'masterCommunity', header: 'Status Description', width: '21%' }
        ];

        if (this.IsAdminPage) {
            this.title = 'Job Queue Control Panel';
        }

        // Removes preflight error on page load
        setTimeout(() => { this.getJobQueues(); });
        this.jobStatsService.getAverageExecutionTimeData(this.selectedTimeFrame).subscribe((data: JobStatDataAverageExecutionTime[]) => {
            this.jobStatDataAverageExecutionTime = data;
            this.jobStatData = data;
        }, (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured getting Job Average Execution Data.' });
        });

        // get jobs every 15 seconds
        setInterval(() => {
            this.getJobQueues();
        }, 15000);
    }

    antiSpamButtonTimer(): void {
        this.setAllButtonEnabled(false);
        setTimeout(() => { this.setAllButtonEnabled(true); }, 2500);
    }

    cancelFeasyJob(jobQueueId: string): void {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to cancel this job?',
            accept: () => {
                this.loading = true;

                this.jobQueueService.cancelJob(jobQueueId).subscribe((response: any) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Canceled',
                        detail: 'Job has been cancelled',
                    });

                    this.getJobQueues();
                });
            }
        });
    }

    checkJobTriggerStatus(): void {
        this.loading = true;
        this.isCheckingTriggers = true;

        this.jobQueueService.getJobTriggerStatus().subscribe(res => {
            this.jobProcessorStatus = res[0].isProcessorActive;
            this.loading = false;
            this.isCheckingTriggers = false;
            this.jobProcessorChecked = true;
        });
    }

    closeCancelErrorModal(): void {
        this.showCancelErrorModal = false;
    }

    get sharepointSiteUrl(): string {
        return environment.sharepointSiteUrl;
    }

    getJobQueues(): void {
        this.jobQueueService.getJobQueues().subscribe((recordset: JobQueue[]) => {
            // Sort results in Created Date descending order
            recordset.sort((a, b) => a.jobCreatedDate.valueOf() < b.jobCreatedDate.valueOf() ? 1 : -1);
            if (recordset) {
                this.requiredJobQueueList = recordset.filter(status => status.jobStatus === "IN-QUEUE" || status.jobStatus == "IN-PROGRESS");
                this.numOfJobs = this.requiredJobQueueList.length;
                if (this.numOfJobs == 0) {
                    this.estimatedWaitTime = 0;
                }
                const currentTime = Date.now();
                this.requiredJobQueueList.forEach(job => {
                    const lastChecked = this.lastCheckedJobs.get(job.jobQueueId);
                    // Exclude the job if it was checked in the last 15 seconds
                    if (lastChecked && (currentTime - lastChecked) < 15000) {
                        return;
                    }
                    this.lastCheckedJobs.set(job.jobQueueId, currentTime);
                });
                this.estimatedWaitTime = 0;
                this.requiredJobQueueList.forEach(item => {
                    const getWaitTime = this.jobStatData.find(job => job.jobType === item.jobType);
                    if (getWaitTime) {
                        this.estimatedWaitTime += getWaitTime.averageExecutionDuration;
                    }
                });
            }

            // Update all Import Anaplan Communities jobs with default values
            recordset.forEach(x => {
                if (x.jobType === 8 || x.jobType === 9) {
                    x.masterCommunityName = 'All Communities';
                    x.divisionName = 'All Divisions';
                }
                x.masterCommunityName += ' - ' + x.divisionName;
            });

            this.jobQueueList = recordset;
            for (let i = 0; i < this.jobQueueList.length; i++) {
                const queue = this.jobQueueList[i];
                let createdDate = queue.jobCreatedDate.toString();
                createdDate += ' UTC';
                queue.jobCreatedDate = new Date(createdDate);

                if (queue.jobDateUpdated !== null && queue.jobDateUpdated !== undefined) {
                    let updatedDate = queue.jobDateUpdated.toString();
                    updatedDate += ' UTC';
                    queue.jobDateUpdated = new Date(updatedDate);
                }

                if (queue.jobStartedDate !== null && queue.jobStartedDate !== undefined) {
                    let startedDate = queue.jobStartedDate.toString();
                    startedDate += ' UTC';
                    queue.jobStartedDate = new Date(startedDate);
                }

                this.jobQueueList[i] = queue;
            }

            this.loading = false;
        }, err => {
            console.log(err);
        });
    }

    modalOpenClick(jobQueueId: string): void {
        // Find the jobQueue info from the list by id
        const jobQueue = this.jobQueueList.find(x => x.jobQueueId === jobQueueId);

        // Populate the modal
        this.errorDivision = jobQueue.divisionName;
        this.errorMasterCommunityName = jobQueue.masterCommunityName;
        this.modalErrorMessageString = jobQueue.jobStatusDescription;
        this.updatedTimeStamp = jobQueue.jobDateUpdated;

        // Display the modal
        this.showErrorModal = true;
    }

    refreshErrorModal(): void {
        // Hide the modal
        this.showErrorModal = false;

        // Give the modal time to fade out before resetting to smooth transition
        // then reset data
        setTimeout(() => {
            this.errorDivision = '';
            this.errorMasterCommunityName = '';
            this.modalErrorMessageString = '';
            this.updatedTimeStamp = null;
        }, 250);
    }

    setAllButtonEnabled(isEnabled: boolean): void {
        this.jobProcessorStatusButtonEnabled = isEnabled;
    }

    updateJobProcessorStatus(): void {
        this.loading = true;

        const param: JobTriggerParam = new JobTriggerParam();
        param.isAutoJobActive = true; 
        param.isProcessorActive = this.jobProcessorStatus === true ? false : true;

        this.setAllButtonEnabled(false);

        this.jobQueueService.updateJobTriggerStatus(param).subscribe(() => {
            this.loading = false;
            this.messageService.add({
                severity: 'success',
                summary: 'Added',
                detail: 'Job Processor now turned ' + (param.isProcessorActive === true ? 'on!' : 'off!')
            });

            this.antiSpamButtonTimer();
            this.checkJobTriggerStatus();
        }, err => {
            this.loading = false;
            this.messageService.add({
                severity: 'error',
                summary: 'Failure',
                detail: 'Failed to update Job Processor Status',
            });

            this.antiSpamButtonTimer();
            this.checkJobTriggerStatus();
            console.log(err);
        });
    }
}
