import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { JobQueue, JobQueueTracking, JobTypeEnum, Subsites } from 'src/app/models';
import { AuthorizationService, JobQueueService, SiteService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-job-history',
    templateUrl: './job-history.component.html',
    styleUrls: ['./job-history.component.css']
})
export class JobHistoryComponent implements OnInit {

    col: any[];
    CommunitySites: Subsites[] = [];
    dataLength: number = 0;
    errorDivision: string = '';
    errorMasterCommunityName: string = '';
    isJobHistoryPage: boolean = false;
    isLoading: boolean = false;
    isPopupVisible: boolean = false;
    isSubmitted: boolean = false;
    jobHistoryData: any[] = [];
    jobTrackInfo: any[] = [];
    jobTypes: any[] = [
        { id: 1, label: 'Pre-Feasy Book' },
        { id: 2, label: 'Full Book' },
        { id: 3, label: 'Exec Book' },
        { id: 4, label: 'B Book' },
        { id: 5, label: 'Refresh Links' },
        { id: 6, label: 'Create Site' },
        { id: 7, label: 'Import Anaplan Data' },
        { id: 8, label: 'Import Anaplan Communities' }
    ];
    masterCommunityInfo: string = '';
    masterCommunityName: string = '';
    modalErrorMessageString: string = '';
    popupCol: any[];
    selectedJobQueueId: string = '';
    selectedJobTypeId: number = 0;
    selectedMstrCommId: number = 0;
    showErrorModal: boolean = false;
    showImportCommunitiesMessage: boolean = false;
    showNoResultsMessage: boolean = false;
    submittedJobTypeTitle: string = '';
    updatedTimeStamp: Date = null;

    constructor(private authService: AuthorizationService, private jobQueueService: JobQueueService, private messageService: MessageService, private siteService: SiteService) { }

    ngOnInit(): void {
        this.selectedJobTypeId = 0;
        this.getSharepointCommunities();
        this.col = [
            { field: 'masterCommunityName', header: 'Master Community - Division', width: '25%' },
            { field: 'jobTypeDescription', header: 'Type', width: '15%' },
            { field: 'submittedBy', header: 'Submitted By', width: '9%' },
            { field: 'jobStartedDate', header: 'Job Started', width: '9%' },
            { field: 'jobDateUpdated', header: 'Last Updated', width: '9%' },
            { field: 'jobStatus', header: 'Status', width: '12%' },
            { field: 'masterCommunity', header: 'Status Description', width: '21%' }
        ];

        this.popupCol = [
            { field: 'taskDescription', header: 'Task Description', width: '50%' },
            { field: 'startTime', header: 'Start Time', width: '50%' },
        ];
    }

    get sharepointSiteUrl(): string {
        return environment.sharepointSiteUrl;
    }

    getSharepointCommunities(): void {
        const roles = this.authService.getRoles();
        this.isJobHistoryPage = true;
        this.siteService.divisionCommunitySites(roles, this.isJobHistoryPage).subscribe((recordset) => {
            this.CommunitySites = recordset.sort((a, b) => a.title.localeCompare(b.title));
        }, err => {
            console.log(err);
        });
    }

    modalOpenClick(jobQueueId: string): void {
        // Find the jobQueue info from the list by id
        const jobQueue = this.jobHistoryData.find(x => x.jobQueueId === jobQueueId);

        // Populate the modal
        this.errorDivision = jobQueue.division;
        this.errorMasterCommunityName = jobQueue.masterCommunityName;
        this.modalErrorMessageString = jobQueue.jobStatusDescription;
        this.updatedTimeStamp = jobQueue.jobDateUpdated;

        // Display the modal
        this.showErrorModal = true;
    }

    onDialogClose() {
        this.isPopupVisible = false;
        this.jobTrackInfo = null;
    }

    openPopup(jobQueueId: string) {
        this.isLoading = true;
        this.isPopupVisible = true;
        this.selectedJobQueueId = jobQueueId;
        this.jobQueueService.getJobTrackingInformation(this.selectedJobQueueId, this.selectedMstrCommId).subscribe((trackData: JobQueueTracking[]) => {
            this.jobTrackInfo = trackData;
            this.isLoading = false;
        }, (error) => {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured getting Job Tracking History Data.' });
        });
    }

    onSubmit(): void {
        this.isLoading = true;
        if (this.selectedJobTypeId == JobTypeEnum.PreFeasyBook) {
            this.submittedJobTypeTitle = "Pre-Feasy Book";
        }
        else if (this.selectedJobTypeId == JobTypeEnum.FullBook) {
            this.submittedJobTypeTitle = "Full Book";
        }
        else if (this.selectedJobTypeId == JobTypeEnum.ExecutiveBook) {
            this.submittedJobTypeTitle = "Exec Book";
        }
        else if (this.selectedJobTypeId == JobTypeEnum.BBook) {
            this.submittedJobTypeTitle = "B Book";
        }
        else if (this.selectedJobTypeId == JobTypeEnum.RefreshLinks) {
            this.submittedJobTypeTitle = "Refresh Links";
        }
        else if (this.selectedJobTypeId == JobTypeEnum.CreateSite) {
            this.submittedJobTypeTitle = "Create Site";
        }
        else if (this.selectedJobTypeId == JobTypeEnum.ImportAnaplanData) {
            this.submittedJobTypeTitle = "Import Anaplan Data";
        }
        else if (this.selectedJobTypeId == JobTypeEnum.ImportAnaplanCommunities) {
            this.submittedJobTypeTitle = "Import Anaplan Communities";
        }

        this.jobQueueService.getJobTrackingHistory(this.selectedMstrCommId, this.selectedJobTypeId).subscribe((data: JobQueue[]) => {
            this.showNoResultsMessage = this.selectedJobTypeId != JobTypeEnum.ImportAnaplanCommunities && data.length === 0;
            this.showImportCommunitiesMessage = this.selectedJobTypeId == JobTypeEnum.ImportAnaplanCommunities && data.length === 0;
            this.jobHistoryData = data;
            this.jobHistoryData.forEach(x => {
                if (x.jobType === 8) {
                    x.masterCommunityName = 'All Communities';
                    x.division = 'All Divisions';
                }
                x.masterCommunityName += ' - ' + x.division;
                this.masterCommunityInfo = x.masterCommunityName;
            });

            this.isLoading = false;
            this.isSubmitted = true;
        }, (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured getting Job Tracking History Data.' });
            this.isLoading = false;
        });
    }

    refreshErrorModal(): void {
        // Hide the modal
        this.showErrorModal = false;
        // Give the modal time to fade out before resetting to smooth transition
        // then reset data
        setTimeout(() => {
            this.errorDivision = '';
            this.errorMasterCommunityName = '';
            this.modalErrorMessageString = '';
            this.updatedTimeStamp = null;
        }, 250);
    }
}
