import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';

import { OrgArea, OrgCounty, OrgDivision, OrgMarket, OrgSubMarket } from '../../../models';
import { OrgService } from '../../../services';

@Component({
    selector: 'app-edit-org',
    templateUrl: './edit-org.component.html',
    styleUrls: ['./edit-org.component.css']
})

export class EditOrgComponent implements OnInit {
    @Input() showEditOrgModal: boolean = false;
    @Input() selectedOrgLevel: string;
    @Input() selectedOrgObject: any;
    @Output() modalClose = new EventEmitter();

    dropdownAreas: OrgArea[] = [];
    dropdownDivisions: OrgDivision[] = [];
    dropdownMarkets: OrgMarket[] = [];
    isSaveReady: boolean = true;
    isLoading: boolean = false;

    constructor(private messageService: MessageService, private orgService: OrgService) { }

    ngOnInit() {
        this.isLoading = true;
        switch (this.selectedOrgLevel) {
            case 'Division':
                this.orgService.getOrgs('Area').subscribe(areas => {
                    this.dropdownAreas = areas;
                    this.isLoading = false;
                });
                break;
            case 'Market':
                this.orgService.getOrgs('Division').subscribe(divisions => {
                    this.dropdownDivisions = divisions;
                    this.isLoading = false;
                });
                break;
            case 'SubMarket':
                this.orgService.getOrgs('Market').subscribe(markets => {
                    this.dropdownMarkets = markets;
                    this.isLoading = false;
                });
                break;
            default:
                break;
        }
    }

    onDeleteOrg() {
        if (confirm(`Are you sure you want to delete this ${this.selectedOrgLevel}?\r\n\r\nNote: Organization levels cannot be deleted if there are lower levels referencing it.`)) {
            if (confirm(`Deleting an organization level could result in multiple layers of orphaned organizational records and cause multiple levels of mapping issues with Feasibility records.\r\n\r\nAre you really sure you want to delete this ${this.selectedOrgLevel}?`)) {
                this.orgService.deleteOrg(this.selectedOrgLevel, this.selectedOrgObject).subscribe((response: any) => {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: this.selectedOrgLevel + ' deleted successfully' });
                    this.onEditModalClose(true);
                }, err => {
                    if (err.error.message.includes('The DELETE statement conflicted with the REFERENCE constraint')) {
                        this.messageService.add({ severity: 'error', summary: 'Organizational Error', detail: this.selectedOrgLevel + ' delete failed due to references lower level organizational references.' });
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Unknown Error', detail: this.selectedOrgLevel + ' delete failed for unknown reasons.' });
                    }
                });
            }
        }
    }

    onEditModalClose(isRefreshQuery: boolean = false) {
        this.selectedOrgObject = null;
        this.modalClose.emit(isRefreshQuery);
        this.showEditOrgModal = false;
    }

    onFieldChange(): void {
        switch (this.selectedOrgLevel) {
            case 'Area':
                if ((this.selectedOrgObject as OrgArea).areaName.length > 0) {
                    this.isSaveReady = true;
                } else {
                    this.isSaveReady = false;
                }
                break;
            case 'County':
                if ((this.selectedOrgObject as OrgCounty).countyName.length > 0 &&
                    (this.selectedOrgObject as OrgCounty).stateCd.length == 2 &&
                    (this.selectedOrgObject as OrgCounty).fipsStateCd.length == 2 &&
                    (this.selectedOrgObject as OrgCounty).fipsCountyCd.length === 3) {
                    this.isSaveReady = true;
                } else {
                    this.isSaveReady = false;
                }
                break;
            case 'Division':
                if ((this.selectedOrgObject as OrgDivision).divName.length > 0 &&
                    (this.selectedOrgObject as OrgDivision).areaId > 0) {
                    this.isSaveReady = true;
                } else {
                    this.isSaveReady = false;
                }
                break;
            case 'Market':
                if ((this.selectedOrgObject as OrgMarket).mktName.length > 0 &&
                    (this.selectedOrgObject as OrgMarket).lawsonMktNbr.length == 4 &&
                    (this.selectedOrgObject as OrgMarket).divId > 0) {
                    this.isSaveReady = true;
                } else {
                    this.isSaveReady = false;
                }
                break;
            case 'SubMarket':
                if ((this.selectedOrgObject as OrgSubMarket).subMktName.length > 0 &&
                    (this.selectedOrgObject as OrgSubMarket).mktId > 0) {
                    this.isSaveReady = true;
                } else {
                    this.isSaveReady = false;
                }
                break;
            default:
                this.isSaveReady = false;
                break;
        }
    }

    onSaveOrg() {
        this.orgService.updateOrg(this.selectedOrgLevel, this.selectedOrgObject).subscribe((response: any) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: this.selectedOrgLevel + ' updated successfully.' });
            this.onEditModalClose(true);
        }, err => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: this.selectedOrgLevel + ' update failed.' });
        });
    }
}
