<div class="well panel">
    <h2><i class="fa fa-sitemap fa-lg"></i> Create Site</h2><br>

    <div class="row">
        <div class="col-lg-12">
            <label class="control-label">Master Community</label>
            <select class="form-control" [disabled]="isLoading" (change)="onMasterCommChange($event.target.value)">
                <option [disabled]="true">Select an Anaplan Community...</option>
                <option *ngFor="let feasy of anaplanFeasyList" [value]="feasy.feasibilityName + '^^' + feasy.feasibilityCode + '^^' + feasy.division + '^^' + feasy.mstrCommId">
                    {{feasy.feasibilityName}} <span *ngIf="feasy.mstrCommId">- {{feasy.mstrCommId}}</span>
                </option>
            </select>
        </div>
        <br />
        <div class="col-lg-6">
            <button type="button" class="btn btn-primary" (click)="onCreateSiteClick()" [disabled]="enableCreateSite === false"><i class="fa fa-align-left"></i> Create Site</button>&nbsp;&nbsp;
            <input type="checkbox" id="import" text="importdata" (change)="checkImport($event.target.checked)" />&nbsp;Include "Import Anaplan Data"&nbsp;&nbsp;
            <span *ngIf="isLoading"><img width="30" height="30" src="../../assets/loading_spinner-alternate.gif" alt="Loading..."></span>
        </div>

        <div class="col-lg-6 text-right">
            <button type="button" class="btn btn-secondary" (click)="onImportAnaplanCommunitiesClick()" [disabled]="disableImportAnaplanCommunities === true"><i class="fa fa-align-right"></i> Import Anaplan Communities</button>
        </div>

        <div class="col-lg-12">
            <ng-container *ngIf="showNotImported">
                <div><i>Data not yet imported for this Master Community</i></div>
            </ng-container>

            <ng-container *ngIf="showImported">
                <div><i>Last Imported: {{ dateImported | date: 'short' }}</i></div>
            </ng-container>

            <ng-container *ngIf="showSiteExists">
                <div><i>Site already exists on SharePoint</i></div>
            </ng-container>

            <ng-container *ngIf="createJobErrorMessage != ''">
                <div>{{createJobErrorMessage}}</div>
            </ng-container>            
        </div>
    </div>
</div>

<p-toast position="top-right"></p-toast>

<app-job-queue-list></app-job-queue-list>
