import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';

type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable()
export class NotificationsService {
    growlNotificationChange: Subject<object> = new Subject<object>();
    messageNotificationChange: Subject<object> = new Subject<object>();

    private message: Message[] = [];

    messageNotifyNow(): void {
        this.messageNotificationChange.next(this.message);
        this.message = [];
    }

    growlNotify(severity: Severities, summary: string, detail: string): void {
        this.growlNotificationChange.next({ severity, summary, detail });
    }

    messageNotify(severity: Severities, summary: string, detail: string): void {
        this.messageNotificationChange.next({ severity, summary, detail });
    }

    addNotification(severity: Severities, summary: string, detail: string): void {
        this.message.push({ severity, summary, detail });
    }
}
