import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {

    constructor(public http: HttpClient) { }

    public getOpts(): any {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*.*'
            })
        };
        return opts;
    }

    public getOptsJson(): any {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*.*',
                Accept: 'application/json'
            })
        };
        return opts;
    }
}
