import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Subsites } from '../models';
import { SharedService } from '.';

@Injectable()
export class SiteService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    createMasterCommunity(createSiteParam: any): Observable<any> {
        const opts = this.sharedService.getOptsJson();
        const postUrl = environment.baseUrl + '/CreateSite/';
        const data = JSON.stringify({
            anaplanFeasyName: createSiteParam.anaplanFeasyName,
            anaplanFeasyId: createSiteParam.anaplanFeasyId,
            area: createSiteParam.area
        });
        return this.http.post(`${postUrl}CreateMasterCommunity`, data, opts);
    }

    divisionCommunitySites(lawsonNumbers: string[], isJobHistoryPage: boolean = false): Observable<any> {
        const opts = this.sharedService.getOptsJson();
        const getUrl = `${environment.baseUrl}/CommunitySite/`;
        return this.http.post(`${getUrl}DivisionCommunities/${isJobHistoryPage}`, lawsonNumbers,opts);
    }

    getCommunitySite(feasyCode: string): Observable<Subsites> {
        const getUrl = environment.baseUrl + '/CommunitySite/';
        return this.http.get<Subsites>(`${getUrl}Get/` + encodeURIComponent(feasyCode));
    }

    getCommunitySites(): Observable<Subsites[]> {
        const getUrl = environment.baseUrl + '/CommunitySite/';
        return this.http.get<Subsites[]>(`${getUrl}Get`);
    }

    getSiteContainsApprovedBooks(masterCommId: number): any {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/RefreshLinks/';
        return this.http.get(`${getUrl}SiteContainsApprovedBooks(${masterCommId})`, opts);
    }
}
