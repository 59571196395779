import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '.';
import { environment } from '../../environments/environment';

@Injectable()
export class JobStatsService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getJobStats(): Observable<any> {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/JobStats/';
        return this.http.get(`${getUrl}GetJobStats`, opts);
    }

    getAverageExecutionTimeData(timeFrame: number): Observable<any> {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/JobStats/';
        return this.http.get(`${getUrl}GetAverageExecutionTimeData/${timeFrame}`, opts);
    }

    getErrorBreakdownData(timeFrame: number): Observable<any> {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/JobStats/';
        return this.http.get(`${getUrl}GetErrorBreakdownData/${timeFrame}`, opts);
    }
}