<p-toast position="top-right"></p-toast>

<div class="well panel">
    <h2> <i class="fa fa-history fa-lg"></i> Job Statistics</h2>
    <br>
    <div class="row">
        <div class="form-group col-sm-6">
            <label class="control-label">Available Statistics</label>
            <select [(ngModel)]="selectedStatType" class="form-control" [disabled]="isLoading"
                (change)="onStatTypeChange($event.target.value)">
                <option [disabled]="true" [selected]="true" [value]="0">Select a Statistic Type...</option>
                <option *ngFor="let type of statTypes" [value]="type.value">
                    {{ type.label }}
                </option>
            </select>
        </div>
        <div class="form-group col-sm-6">
            <label class="control-label">Time Frame</label>
            <div class="flex align-items-center">
                <p-radioButton name="timeFrame" value="7" [(ngModel)]="selectedTimeFrame" inputId="timeFrame7"
                    class="radio-button"></p-radioButton>
                <label for="timeFrame7" class="radio-label">
                    7 days
                </label>
                |
                <p-radioButton name="timeFrame" value="30" [(ngModel)]="selectedTimeFrame" inputId="timeFrame30"
                    class="radio-button"></p-radioButton>
                <label for="timeFrame30" class="radio-label">
                    30 days
                </label>
                |
                <p-radioButton name="timeFrame" value="60" [(ngModel)]="selectedTimeFrame" inputId="timeFrame60"
                    class="radio-button"></p-radioButton>
                <label for="timeFrame60" class="radio-label">
                    60 days
                </label>
                |
                <p-radioButton name="timeFrame" value="90" [(ngModel)]="selectedTimeFrame" inputId="timeFrame90"
                    class="radio-button"></p-radioButton>
                <label for="timeFrame90" class="radio-label">
                    90 days
                </label>
                |
                <p-radioButton name="timeFrame" value="365" [(ngModel)]="selectedTimeFrame" inputId="timeFrame365"
                    class="radio-button"></p-radioButton>
                <label for="timeFrame365" class="radio-label">
                    365 days
                </label>
            </div>
        </div>
    </div>
    <div class="button-area row">
        <div class="form-group col-sm-12">
            <button type="button" class="btn btn-primary" (click)="onSubmit()"
                [disabled]="selectedStatType == '0' || isLoading">
                <i class="fa fa-search"></i>&nbsp; Search
            </button>
            <img src="../../assets/loading_spinner-alternate.gif" *ngIf="isLoading" class="smaller-loader"
                alt=" Loading...">
        </div>
    </div>
    <div class="row" *ngIf="submittedStatType != '' && isSubmitted">
        <div class="well panel">
            <h2><i class="fa fa-clock-o fa-lg" *ngIf="submittedStatType == 'averageExecutionTime'"
                    aria-hidden="true"></i>
                <i class="fa fa-exclamation-circle fa-lg" *ngIf="submittedStatType == 'errorBreakdown'"
                    aria-hidden="true"></i>
                &nbsp;{{submittedStatTypeTitle}} &nbsp;
            </h2>
            <br />
            <p-table #dt styleClass="p-datatable-striped" [columns]="cols" [value]="jobStatData" [paginator]="false"
                [rows]="10" [responsive]="true" [loading]="loading">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns" style="overflow: hidden;">
                            <ng-container *ngIf="col.dataType === 'duration'; else second" #first>
                                {{ data[col.field] | timer }}
                            </ng-container>
                            <ng-template #second>
                                <ng-container *ngIf="col.dataType === 'percentage'; else third">
                                    {{ data[col.field] | number : '1.2-2' }}%
                                </ng-container>
                            </ng-template>
                            <ng-template #third>
                                <ng-container *ngIf="col.dataType === 'errors'; else fourth">
                                    <button *ngIf="data[col.field].length > 0" class="btn btn-sm btn-danger"
                                        id="{{data['jobTypeName']}}" (click)="modalOpenClick($event.target.id)">
                                        <i class="fa fa-times-circle"></i> View Top Errors
                                    </button>
                                </ng-container>
                            </ng-template>
                            <ng-template #fourth>
                                <ng-container>
                                    {{ data[col.field] }}
                                </ng-container>
                            </ng-template>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-dialog position="center" header="Top Errors" [(visible)]="showErrorModal" [draggable]="true" resizable="true"
    [modal]="true" [style]="{'max-width':'75%'}">
    <h4>Top {{ selectedErrorJobMessages.length }} Error Messages for Job Type: {{ selectedErrorJobType }}</h4>
    <p class="p-m-0" *ngFor="let msg of selectedErrorJobMessages">
        <br>
        {{ msg.messageText }}
        <br>
        {{ msg.timesOccured }}
        <br>
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="modalCloseClick()" label="Close" class="p-button-text"></p-button>
    </ng-template>
</p-dialog>