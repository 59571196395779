import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthorizationService } from '../services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent {
    authInterval: any;

    constructor(private authorizationService: AuthorizationService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.authInterval = setInterval(() => {
            this.verifyAuthentication();
        }, 500);
    }

    verifyAuthentication(): void {
        if (this.authorizationService.authenticated()) {
            this.router.navigate(['/reports'], { relativeTo: this.route });
            clearInterval(this.authInterval);
        }
    }
}

