import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthorizationService } from '../services';

@Injectable()
export class OrgService {

    userId: string;

    constructor(private authService: AuthorizationService, private http: HttpClient) {
        this.userId = this.authService.getUsername();
    }

    createOrg(newOrgObj: any, selectedOrgLevel: string): Observable<any> {
        const opts = this.getOptsJson();
        return this.http.post(`${environment.baseUrl}/org/create/${selectedOrgLevel}/${this.userId}`, newOrgObj, opts);
    }

    deleteOrg(selectedOrg: string, orgObj: any): Observable<any> {
        const opts = this.getOptsJson();
        return this.http.post(`${environment.baseUrl}/org/delete/${selectedOrg}`, orgObj, opts);
    }

    getOrgs(selectedOrg: string): Observable<any> {
        const opts = this.getOpts();
        return this.http.get(`${environment.baseUrl}/org/get/${selectedOrg}`, opts);
    }

    updateOrg(selectedOrg: string, orgObj: any): Observable<any> {
        const opts = this.getOptsJson();
        return this.http.post(`${environment.baseUrl}/org/update/${selectedOrg}/${this.userId}`, orgObj, opts);
    };

    private getOpts(): any {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*.*'
            })
        };
        return opts;
    }

    private getOptsJson(): any {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*.*',
                Accept: 'application/json'
            })
        };
    }
}