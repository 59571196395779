import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
    @Input() isAdmin: boolean = false;
    currentRoute: string = null;

    get sharepointSiteUrl(): string {
        return environment.sharepointSiteUrl;
    }

    get documentationSiteUrl(): string {
        return environment.documentationSiteUrl;
    }

    get isAdminSelected(): boolean {
        return this.currentRoute?.toLowerCase().indexOf("admin") > -1;
    }

    get isCreateSiteSelected(): boolean {
        return this.currentRoute?.toLowerCase() === "createsite";
    }

    get isCreateBookSelected(): boolean {
        return this.currentRoute?.toLowerCase() === "createbook";
    }

    get isRefreshLinksSelected(): boolean {
        return this.currentRoute?.toLowerCase() === "refreshlinks";
    }

    get isReportsSelected(): boolean {
        return this.currentRoute?.toLowerCase() === "reports";
    }

    get isJobQueueManagerSelected(): boolean {
        return this.currentRoute?.toLowerCase() === "admin/job-queue-manager";
    }

    get isJobStatsSelected(): boolean {
        return this.currentRoute?.toLowerCase() === "admin/job-stats";
    }

    get isJobHistorySelected(): boolean {
        return this.currentRoute?.toLowerCase() === "admin/job-history";
    }

    get isOrgManagerSelected(): boolean {
        return this.currentRoute?.toLowerCase() === "admin/org-manager";
    }

    get isSiteBannerSelected(): boolean {
        return this.currentRoute?.toLowerCase() === "admin/site-banner";
    }

    get anaplanUrl(): string {
        return environment.anaplanUrl;
    }

    constructor(private router: Router) { }

    ngOnInit(): void {
        this.router.events.subscribe((val) => {
            this.currentRoute = this.router.url.replace("/", "");
        });
    }
}
