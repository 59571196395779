import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

import { JobStatDataAverageExecutionTime, JobStatDataErrorBreakdown, TopErrorMessages } from '../../models';
import { JobStatsService } from '../../services';

@Component({
    selector: 'app-job-stats',
    templateUrl: './job-stats.component.html',
    styleUrls: ['./job-stats.component.css']
})
export class JobStatsComponent implements OnInit {

    cols: any[]; // Table columns
    isLoading: boolean = false;
    isSubmitted: boolean = false;
    jobStatData: any[] = [];
    jobStatDataAverageExecutionTime: JobStatDataAverageExecutionTime[] = [];
    jobStatDataErrorBreakdown: JobStatDataErrorBreakdown[] = [];
    selectedStatType: string = '';
    selectedTimeFrame: number = 30;
    selectedErrorJobMessages: TopErrorMessages[] = [];
    selectedErrorJobType: string = '';
    showErrorModal: boolean = false;
    submittedStatType: string = '';
    submittedStatTypeTitle: string = '';
    statTypes: any[] = [
        { label: 'Average Execution Time', value: 'averageExecutionTime' },
        { label: 'Error Breakdown', value: 'errorBreakdown' }
    ];

    constructor(private messageService: MessageService, private jobStatsService: JobStatsService) { }

    ngOnInit(): void {
        this.selectedStatType = '0';
    }

    modalOpenClick(jobTypeName: string) {
        this.selectedErrorJobType = jobTypeName;
        this.selectedErrorJobMessages = this.jobStatDataErrorBreakdown.find(x => x.jobTypeName === jobTypeName).topErrorMessages;
        this.showErrorModal = true;
    }

    modalCloseClick() {
        this.selectedErrorJobType = '';
        this.selectedErrorJobMessages = [];
        this.showErrorModal = false;
    }

    onStatTypeChange(statType: string): void {
        this.selectedStatType = statType;
    }

    onSubmit(): void {
        this.isLoading = true;
        this.submittedStatType = this.selectedStatType;
        this.jobStatData = null;
        if (this.submittedStatType === 'averageExecutionTime') {
            this.submittedStatTypeTitle = 'Average Execution Time';
            this.cols = [
                { field: 'jobTypeName', header: 'Job Type', width: '20%', dataType: 'standard' },
                { field: 'jobCount', header: 'Job Count', width: '20%', dataType: 'standard' },
                { field: 'averageWaitTime', header: 'Avg Wait Time', width: '20%', dataType: 'duration' },
                { field: 'averageExecutionDuration', header: 'Avg Execution Duration', width: '20%', dataType: 'duration' },
                { field: 'averageTotalDuration', header: 'Avg Total Duration', width: '20%', dataType: 'duration' }
            ];
        } else if (this.submittedStatType === 'errorBreakdown') {
            this.submittedStatTypeTitle = 'Error Breakdown';
            this.cols = [
                { field: 'jobTypeName', header: 'Job Type', width: '15%', dataType: 'standard' },
                { field: 'jobCount', header: 'Job Count', width: '10%', dataType: 'standard' },
                { field: 'errorCount', header: 'Error Count', width: '10%', dataType: 'standard' },
                { field: 'successCount', header: 'Success Count', width: '10%', dataType: 'standard' },
                { field: 'cancelCount', header: 'Cancel Count', width: '10%', dataType: 'standard' },
                { field: 'errorPercentage', header: 'Error Pct', width: '10%', dataType: 'percentage' },
                { field: 'successPercentage', header: 'Success Pct', width: '10%', dataType: 'percentage' },
                { field: 'cancelPercentage', header: 'Cancel Pct', width: '10%', dataType: 'percentage' },
                { field: 'topErrorMessages', header: 'Top Error Messages', width: '15%', dataType: 'errors' }
            ];
        }

        if (this.submittedStatType === 'averageExecutionTime') {
            // Call jobStats.Service to get average execution time data
            this.jobStatsService.getAverageExecutionTimeData(this.selectedTimeFrame).subscribe((data: JobStatDataAverageExecutionTime[]) => {
                this.jobStatDataAverageExecutionTime = data;
                this.jobStatData = data;
                this.isSubmitted = true;
                this.isLoading = false;
            }, (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured getting Job Average Execution Data.' });
                this.isLoading = false;
            });
        } else if (this.submittedStatType === 'errorBreakdown') {
            // Call jobStats.Service to get error breakdown data
            this.jobStatsService.getErrorBreakdownData(this.selectedTimeFrame).subscribe((data: JobStatDataErrorBreakdown[]) => {
                data.forEach((row) => {
                    row.errorPercentage = row.errorCount / row.jobCount * 100;
                    row.successPercentage = row.successCount / row.jobCount * 100;
                    row.cancelPercentage = row.cancelCount / row.jobCount * 100;
                });

                this.jobStatDataErrorBreakdown = data;
                this.jobStatData = data;
                this.isSubmitted = true;
                this.isLoading = false;
            }, (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured getting Job Error Breakdown Data.' });
                this.isLoading = false;
            });

        }
    }
}