import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { CreatesiteComponent } from './createsite/createsite.component';
import { CreatebookComponent } from './createbook/createbook.component';
import { LoginComponent } from './login/login.component';
import { JobHistoryComponent, JobQueueManagerComponent, JobStatsComponent, OrgManagerComponent, SiteBannerManagerComponent } from './admin';
import { RefreshlinksComponent } from './refreshlinks/refreshlinks.component';
import { ReportsComponent } from './reports/reports.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

import { AuthGuard } from './authGuard';
import { Claims } from './models';

const routes: Routes = [
    { path: '', pathMatch: 'full', component: LoginComponent },
    { path: 'createsite', component: CreatesiteComponent, canActivate: [AuthGuard], data: { requiresClaim: Claims.feasy_users } },
    { path: 'createbook', component: CreatebookComponent, canActivate: [AuthGuard], data: { requiresClaim: Claims.feasy_users } },
    { path: 'refreshlinks', component: RefreshlinksComponent, canActivate: [AuthGuard], data: { requiresClaim: Claims.feasy_users } },
    { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard], data: { requiresClaim: Claims.feasy_users } },
    { path: 'admin/job-queue-manager', component: JobQueueManagerComponent, canActivate: [AuthGuard], data: { requiresClaim: Claims.feasy_admins } },
    { path: 'admin/job-stats', component: JobStatsComponent, canActivate: [AuthGuard], data: { requiresClaim: Claims.feasy_admins } },
    { path: 'admin/job-history', component: JobHistoryComponent, canActivate: [AuthGuard], data: { requiresClaim: Claims.feasy_admins } },
    { path: 'admin/org-manager', component: OrgManagerComponent, canActivate: [AuthGuard], data: { requiresClaim: Claims.feasy_admins } },
    { path: 'admin/site-banner', component: SiteBannerManagerComponent, canActivate: [AuthGuard], data: { requiresClaim: Claims.feasy_admins } },
    { path: 'unauthorized', component: UnauthorizedComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
