import { Component, OnInit } from '@angular/core';

import { OrgService } from '../../services';

@Component({
    selector: 'app-org-manager',
    templateUrl: './org-manager.component.html',
    styleUrls: ['./org-manager.component.css']
})
export class OrgManagerComponent implements OnInit {

    cols: any[];
    isLoading: boolean = false;
    orgData: any[] = [];
    orgLevels: string[] = [];
    selectedOrgLevel: string;
    selectedOrgObject: any;
    showAddOrgModal: boolean = false;
    showEditOrgModal: boolean = false;

    constructor(private orgService: OrgService) { }

    ngOnInit(): void {
        this.orgLevels = ['Area', 'County', 'Division', 'Market', 'SubMarket'];
        this.selectedOrgLevel = '0';
    }

    buildCols(): void {
        switch (this.selectedOrgLevel) {
            case 'Area':
                this.cols = [
                    { field: 'areaId', header: 'Area Id' },
                    { field: 'areaName', header: 'Area Name' },
                    { field: 'insUserId', header: 'Inserted By' },
                    { field: 'insDt', header: 'Insert Date', type: 'date' },
                    { field: 'updtUserId', header: 'Updated By' },
                    { field: 'updtDt', header: 'Last Updated', type: 'date' },
                    { field: 'edit', header: 'Edit', type: 'button' }
                ];
                break;
            case 'County':
                this.cols = [
                    { field: 'countyId', header: 'County Id' },
                    { field: 'stateCd', header: 'State' },
                    { field: 'fipsStateCd', header: 'FIPS State Cd', hidden: true },
                    { field: 'fipsCountyCd', header: 'FIPS County Cd', hidden: true },
                    { field: 'countyName', header: 'County Name' },
                    { field: 'insUserId', header: 'Inserted By' },
                    { field: 'insDt', header: 'Insert Date', type: 'date' },
                    { field: 'updtUserId', header: 'Updated By' },
                    { field: 'updtDt', header: 'Last Updated', type: 'date' },
                    { field: 'edit', header: 'Edit', type: 'button' }
                ];
                break;
            case 'Division':
                this.cols = [
                    { field: 'divId', header: 'Division Id' },
                    { field: 'divName', header: 'Division Name' },
                    { field: 'areaId', header: 'Area Id' },
                    { field: 'areaName', header: 'Area Name' },
                    { field: 'supportDivInd', header: 'Support Division Ind' },
                    { field: 'insUserId', header: 'Inserted By' },
                    { field: 'insDt', header: 'Insert Date', type: 'date' },
                    { field: 'updtUserId', header: 'Updated By' },
                    { field: 'updtDt', header: 'Last Updated', type: 'date' },
                    { field: 'edit', header: 'Edit', type: 'button' }
                ];
                break;
            case 'Market':
                this.cols = [
                    { field: 'mktId', header: 'Market Id' },
                    { field: 'mktName', header: 'Market Name' },
                    { field: 'lawsonMktNbr', header: 'Lawson Market Number' },
                    { field: 'divId', header: 'Division Id' },
                    { field: 'divName', header: 'Division Name' },
                    { field: 'insUserId', header: 'Inserted By' },
                    { field: 'insDt', header: 'Insert Date', type: 'date' },
                    { field: 'updtUserId', header: 'Updated By' },
                    { field: 'updtDt', header: 'Last Updated', type: 'date' },
                    { field: 'edit', header: 'Edit', type: 'button' }
                ];
                break;
            case 'SubMarket':
                this.cols = [
                    { field: 'subMktId', header: 'SubMarket Id' },
                    { field: 'subMktName', header: 'SubMarket Name' },
                    { field: 'mktId', header: 'Market Id' },
                    { field: 'mktName', header: 'Market Name' },
                    { field: 'insUserId', header: 'Inserted By' },
                    { field: 'insDt', header: 'Insert Date', type: 'date' },
                    { field: 'updtUserId', header: 'Updated By' },
                    { field: 'updtDt', header: 'Last Updated', type: 'date' },
                    { field: 'edit', header: 'Edit', type: 'button' }
                ];
                break;
            default:
                this.cols = [];
                break;
        }
    }

    getOrgs(): void {
        this.orgData = [];
        this.isLoading = true;
        this.buildCols();

        this.orgService.getOrgs(this.selectedOrgLevel).subscribe(result => {
            this.orgData = result;
            this.isLoading = false;
        });
    }

    onAddOrgClick(): void {
        this.showAddOrgModal = true;
    }

    onAddModalClose(params): void {
        const isRefreshQuery = params[0];
        const selectedOrgLevel = params[1];
        this.showAddOrgModal = false;

        if (this.selectedOrgLevel == '0') {
            this.selectedOrgLevel = selectedOrgLevel;
        }

        if (isRefreshQuery) {
            this.getOrgs();
        }
    }

    onEditButtonClick(rowData): void {
        this.selectedOrgObject = rowData;
        this.showEditOrgModal = true;
    }

    onEditModalClose(isRefreshQuery: boolean): void {
        this.showEditOrgModal = false;
        this.selectedOrgObject = null;
        if (isRefreshQuery) {
            this.getOrgs();
        }
    }

    onOrgLevelChange(value): void {
        this.selectedOrgLevel = value;

        this.getOrgs();
    }

    onRefreshClick(): void {
        this.getOrgs();
    }
}
