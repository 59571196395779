import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { config } from '../authConfig';
import { Claims } from '../models';

@Injectable()
export class AuthorizationService {
    constructor(private oAuthService: OAuthService) {
        this.oAuthService.configure(config);
        this.oAuthService.timeoutFactor = 0.3;
        this.oAuthService.setupAutomaticSilentRefresh();
        this.oAuthService.setStorage(sessionStorage);

        this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
            if (!this.oAuthService.hasValidIdToken()) {
                this.oAuthService.initLoginFlow();
            }
        });
    }

    hasClaim(requiredClaim: Claims): boolean {
        let retval = false;
        // tslint:disable-next-line: no-string-literal
        this.oAuthService.getIdentityClaims()?.['roles']?.forEach(element => {
            if (element?.includes(requiredClaim)) {
                retval = true;
                return retval;
            }
        });
        return retval;
    }

    isInRole(): boolean {
        let retval = false;
        // tslint:disable-next-line: no-string-literal
        this.oAuthService.getIdentityClaims()?.['roles']?.forEach(element => {
            if ((element?.includes(Claims.feasy_users)) || (element?.includes(Claims.feasy_restricted_users)) || (element?.includes(Claims.feasy_admins))) {
                retval = true;
                return retval;
            }
        });
        return retval;
    }

    isFeasyRestrictedUser(): boolean {
        let retval = false;
        // tslint:disable-next-line: no-string-literal
        this.oAuthService.getIdentityClaims()?.['roles']?.forEach(element => {
            if (element?.includes(Claims.feasy_restricted_users)) {
                retval = true;
                return retval;
            }
        });
        return retval;
    }

    isFeasyUser(): boolean {
        let retval = false;
        // tslint:disable-next-line: no-string-literal
        this.oAuthService.getIdentityClaims()?.['roles']?.forEach(element => {
            if (element?.includes(Claims.feasy_users)) {
                retval = true;
                return retval;
            }
        });
        return retval;
    }

    isFeasyAdmin(): boolean {
        let retval = false;
        // tslint:disable-next-line: no-string-literal
        this.oAuthService.getIdentityClaims()?.['roles']?.forEach(element => {
            if (element?.includes(Claims.feasy_admins)) {
                retval = true;
                return retval;
            }
        });
        return retval;
    }

    authenticated(): boolean {
        return !!this.oAuthService.hasValidIdToken();
    }

    getRole(): string {
        // tslint:disable-next-line: no-string-literal
        this.oAuthService.getIdentityClaims()?.['roles']?.forEach(element => {
            if (element) {
                return element;
            }
        });
        return '';
    }

    getRoleByName(claimsRole: string): string {
        // tslint:disable-next-line: no-string-literal
        this.oAuthService.getIdentityClaims()?.['roles']?.forEach(element => {
            if (element === claimsRole) {
                return element;
            }
        });
        return '';
    }

    getRoles(): string[] {
        const roles: string[] = [];
        // tslint:disable-next-line: no-string-literal
        this.oAuthService.getIdentityClaims()?.['roles']?.forEach(element => {
            if (element) {
                roles.push(element);
            }
        });
        return roles;
    }

    getUsername(): string {
        // tslint:disable-next-line: no-string-literal
        return this.oAuthService.getIdentityClaims()?.['preferred_username'] ?? '';
    }

}
